import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Patch, Get, Post } from "../Axios/AxiosFunctions";
import Pagination from "../components/Pagination";
import TableRowsLoader from "../components/TableRowsLoader";
import moment from "moment";
import { BaseURL, apiHeader, recordsLimit } from "../Config/apiUrl";
import { Button, Form } from "react-bootstrap";
import AreYouSureModal from "../components/AreYouSureModal";
import { toast } from "react-toastify";
import ScreenBoiler from "../components/ScreenBoiler";
import UserDetailModal from "../components/UserDetailModal";
import { IoMdAddCircleOutline } from "react-icons/io";
import MuiButton from "@mui/material/Button";
import AddUnclaimedCompaniesModal from "../components/AddUnclaimedCompaniesModal";
import { useLocation } from "react-router-dom";
import { useDebounce } from "use-debounce";

function UsersScreen() {
  const [isAddAndEditModalOpen, setIsAddAndEditModalOpen] = useState(false);

  const [limit, setLimit] = useState(100);
  const [pageNumber, setPageNumber] = useState(1);
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const companyStatus = useSelector((state) => state);
  // console.log(companyStatus, "companyStatuscompanyStatuscompanyStatus");
  const [apiData, setApiData] = useState([]);
  const [isGetting, setIsGetting] = useState(false);

  const [isAreYouSureModalOpen, setIsAreYouSureModalOpen] = useState(false);
  const [isDeleteApiCall, setIsDeleteApiCall] = useState(false);
  const [deletingId, setDeletingId] = useState(null);
  const [search, setSearch] = useState("all");
  const [isAddingCompanies, setIsAddingCompanies] = useState(false);
  const [companyToggle, setCompanyToggle] = useState(false);
  const [statusToggle, setStatusToggle] = useState(false);
  const location = useLocation();
  const [searchFilter, setSearchFilter] = useState("");
  const [checkBox, setcheckBox] = useState([]);
  const [isChecked, setIsChecked] = useState();
  const [value] = useDebounce(searchFilter, 1000);

  const handleSerach = async (val) => {
    const response = await Get(
      BaseURL(
        `users/admin/all?page=${pageNumber}&limit=${limit}&status=${
          location?.state == null ? "all" : location?.state
        }&search=${val}`
      ),
      accessToken
    );
    if (response != undefined) {
      setApiData(response?.data?.data?.data);
    }
  };

  useEffect(() => {
    handleSerach(value);
  }, [value]);

  const [detail, setDetail] = useState({
    isApiCall: false,
    data: null,
    index: null,
    isModalOpen: false,
  });

  console.log(location?.state, "locationlocation");

  // get Item handler
  const getData = async (pageNo, newLimit, search) => {
    const url = BaseURL(
      `users/admin/all?page=${pageNo}&limit=${newLimit}&status=${search}`
    );

    setIsGetting(true);
    const response = await Get(url, accessToken);
    setIsGetting(false);
    if (response !== undefined) {
      setApiData(response?.data?.data?.data);
    }
  };

  // deleteItemHandler
  const deleteItemHandler = async () => {
    const url = BaseURL(`users/active/${deletingId?.id}`);

    setIsDeleteApiCall(true);
    const response = await Patch(
      url,
      { active: deletingId?.isActive },
      apiHeader(accessToken)
    );
    setIsDeleteApiCall(false);

    if (response !== undefined) {
      const dataCopy = [...apiData];
      const index = dataCopy.findIndex((item) => item._id === deletingId?.id);
      dataCopy[index] = response.data?.data;
      setApiData(dataCopy);
      setIsAreYouSureModalOpen(false);
      toast.success(
        `Company ${
          deletingId?.isActive === "active" ? "Activated" : "Deactivated"
        } succeccfully!`
      );
    }
  };

  // fire on component mount
  useEffect(() => {
    if (location?.state != null) {
      console.log(location?.state, "locationlocationlocation");
      setSearch(location?.state);
      getData(1, limit, location?.state);
    } else {
      getData(1, limit, location?.state == null ? "all" : location?.state);
    }
  }, [location?.state]);

  // pagination handler
  const HandlepageChange = (page, limit, search) => {
    setPageNumber(page);
    getData(page, limit, search);
  };

  // get singlr user detail
  const getSingleUserDetail = async (userId, index) => {
    const url = BaseURL(`users/admin/${userId}`);
    setDetail((p) => ({ ...p, isApiCall: true, index: index }));
    const response = await Get(url, accessToken);
    if (response !== undefined)
      setDetail((p) => ({
        ...p,
        isApiCall: false,
        index: null,
        data: response?.data?.data,
        isModalOpen: true,
      }));
    else
      setDetail((p) => ({
        ...p,
        isApiCall: false,
        index: null,
        data: null,
        isModalOpen: false,
      }));
  };

  // addCompaniesHndler
  const addCompaniesHndler = async (params) => {
    const formData = new FormData();
    formData.append("csv", params.csv);
    const url = BaseURL("users/uploadCompaniesInfo");

    setIsAddingCompanies(true);
    const response = await Post(url, formData, apiHeader(accessToken, true));
    setIsAddingCompanies(false);

    if (response !== undefined) {
      toast.success("Data uploaded successfully.");
    } else {
      formData.delete("csv");
    }
  };
  const handleSortName = () => {
    const tempArray = [...apiData];

    tempArray.sort((a, b) => {
      let fa = a.companyName.toLowerCase(),
        fb = b.companyName.toLowerCase();

      if (companyToggle) {
        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
      } else {
        if (fa > fb) {
          return -1;
        }
        if (fa < fb) {
          return 1;
        }
      }

      return 0;
    });
    setApiData(tempArray);
    setCompanyToggle((prev) => !prev);
  };
  const handleSortStatus = () => {
    const tempArray = [...apiData];

    tempArray.sort((a, b) => {
      let fa = a.active.toLowerCase(),
        fb = b.active.toLowerCase();

      if (statusToggle) {
        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
      } else {
        if (fa > fb) {
          return -1;
        }
        if (fa < fb) {
          return 1;
        }
      }

      return 0;
    });
    setApiData(tempArray);
    setStatusToggle((prev) => !prev);
  };

  // huss =====>>>>>>>>>>
  const handleCheked = (event) => {
    console.log(event.target.checked, event.target.id);
    // checkBox.push(event.target.id);
    setcheckBox((oldArray) => [...oldArray, event.target.id]);
    if (event.target.checked == false) {
      const tempArray = [...checkBox];
      const index = tempArray?.findIndex((item) => item == event.target.id);
      tempArray.splice(index, 1);
      setcheckBox(tempArray);
    }
  };

  const checkMail = async () => {
    const body = {
      ids: checkBox,
    };
    for (var key in body) {
      if (body[key].length == 0) {
        return toast.error("Please check in first !!");
      }
    }
    const apiUrl = BaseURL(`users/unclaim/mail`);
    const response = await Post(apiUrl, body, apiHeader(accessToken));
    if (response !== undefined) {
      toast.success("Unclaimed Mail Send Succesfully");
      setcheckBox([]);
      setIsChecked(false);
    }
  };

  useEffect(() => {
    if (isChecked == false) setIsChecked(null);
  }, [isChecked]);
  console.log(isChecked, "isChecked isChecked isChecked");
  return (
    <ScreenBoiler>
      <section className="reported-ads mt-5">
        <div className="fieldSetHeaderContainer d-flex flex-column">
          <h1>Companies</h1>
        </div>

        <div className="fieldSetMainContainer">
          <div className="place-add-btn-container mb-4">
            <div className="d-flex align-items-center">
              <p className="m-0 me-3 fw-bold">Records Per Page:</p>
              <select
                value={limit}
                onChange={(e) => {
                  setLimit(e.target.value);
                  setPageNumber(1);
                  HandlepageChange(1, e.target.value, search);
                }}
                className="px-3 py-2 custom-selection"
              >
                <option value={100} className="pt-2">
                  100
                </option>
                <option value={200}>200</option>
                <option value={500}>500</option>
              </select>
            </div>

            <div>
              <input
                className="searchField_b"
                placeholder="Search Companies"
                onChange={(e) => setSearchFilter(e.target.value)}
                value={searchFilter}
              />
            </div>
            {/* Filter */}
            {/* <div className="d-flex align-items-center"> */}
            {/* <p className="m-0 me-3 fw-bold">View:</p> */}
            {/* <select
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  setPageNumber(1);
                  getData(1, limit, e.target.value);
                }}
                className="px-3 py-2 custom-selection"
              >
                <option value={"all"}>All Companies</option>
                <option value={true}>Active Companies</option>
                <option value={false}>Deactive Companies</option>
                <option value={"unclaimed"}>Unclaimed Business</option>
              </select> */}
            {/* </div> */}
            <div className="d-flex">
              <Button
                disabled={checkBox?.length <= 1}
                onClick={checkMail}
                style={{ fontSize: "12px" }}
                className="mailSend-btn"
              >
                Multiple mail send
              </Button>

              {search === "unclaimed" && (
                <div className="d-flex align-items-center">
                  <MuiButton
                    onClick={() => {
                      setIsAddAndEditModalOpen(true);
                    }}
                    variant="contained"
                    color="primary"
                    className="custom-btn rounded-lg radius-8 ms-4"
                    startIcon={<IoMdAddCircleOutline />}
                  >
                    Add
                  </MuiButton>
                </div>
              )}
            </div>
          </div>

          {/* Table */}
          {isGetting ? (
            <TableRowsLoader />
          ) : (
            <div className="col-md-12 table_recent">
              <div className="table-responsive">
                <table
                  className="table"
                  id="dataTable"
                  width="100%"
                  cellspacing="0"
                >
                  <thead>
                    <tr>
                      {search == "unclaimed" && <th></th>}
                      <th>#</th>
                      <th onClick={handleSortName}>Name</th>
                      <th>Email</th>
                      <th>State</th>
                      <th>City</th>
                      <th>Contact #</th>
                      <th onClick={handleSortStatus}>Status</th>
                      <th>Company Status</th>
                      {search == "unclaimed" && <th>Unclaimed Mail</th>}
                      <th>Member Since</th>
                      {search && <th>Actions</th>}
                    </tr>
                  </thead>

                  <tbody>
                    {apiData?.length > 0 &&
                      apiData?.map((item, index) => {
                        console.log(index, "indexindex");
                        return (
                          <tr key={item?._id}>
                            {search == "unclaimed" && (
                              <td>
                                <span>
                                  <Form.Check
                                    onChange={handleCheked}
                                    id={item?._id}
                                    aria-label="option 1"
                                    checked={isChecked}
                                  />
                                </span>
                              </td>
                            )}
                            <td>{++index}</td>
                            <td className="t-t-c">{item?.companyName}</td>
                            <td>{item?.email}</td>
                            <td>{item?.state?.state}</td>
                            <td>{item?.city}</td>
                            <td>{item?.contact}</td>
                            <td>
                              {item?.active == "active"
                                ? "Active"
                                : "Deactivated"}
                            </td>
                            <td>
                              {item?.claimStatus ? "Active" : "Deactivated"}
                            </td>
                            {search == "unclaimed" && (
                              <td>{item?.unclaimedMailsentCount}</td>
                            )}
                            <td>{moment(item?.createdAt).format("ll")}</td>

                            {search !== "unclaimed" ? (
                              <td>
                                <ul
                                  style={{ paddingLeft: "0" }}
                                  className="d-flex"
                                >
                                  {/* Detail */}
                                  <li
                                    className="page-item align-self-end me-1"
                                    onClick={() => {
                                      getSingleUserDetail(item?._id, --index);
                                      setDetail((p) => ({
                                        ...p,
                                        index: index,
                                      }));
                                    }}
                                  >
                                    <Button
                                      className="update-btn "
                                      disabled={detail?.index === index - 1}
                                    >
                                      {detail?.index === index - 1
                                        ? "Please Wait..."
                                        : "View Detail"}
                                    </Button>
                                  </li>
                                  <li
                                    className="page-item align-self-end ml-2"
                                    onClick={() => {
                                      setDeletingId({
                                        id: item?._id,
                                        isActive:
                                          item?.active !== "active"
                                            ? "active"
                                            : "system-deactivated",
                                      });
                                      setIsAreYouSureModalOpen(true);
                                    }}
                                  >
                                    <Button className="delete-btn">
                                      {item?.active === "active"
                                        ? "Deactivate"
                                        : "Activate"}
                                    </Button>
                                  </li>
                                </ul>
                              </td>
                            ) : (
                              <td>
                                <ul
                                  style={{ paddingLeft: "0" }}
                                  className="d-flex"
                                >
                                  {search == "unclaimed" &&
                                    (checkBox?.length > 1 ? (
                                      index === 1 && (
                                        <li
                                          style={{ marginLeft: "4px" }}
                                          className="page-item align-self-end me-2"
                                        ></li>
                                      )
                                    ) : (
                                      <li
                                        style={{ marginLeft: "4px" }}
                                        className="page-item align-self-end me-2"
                                      >
                                        <Button
                                          onClick={checkMail}
                                          disabled={
                                            !checkBox.includes(item?._id)
                                          }
                                          style={{ fontSize: "12px" }}
                                          className="mailSend-btn"
                                        >
                                          Mail send
                                        </Button>
                                      </li>
                                    ))}
                                </ul>
                              </td>
                            )}
                          </tr>
                        );
                      })}

                    {apiData?.length == 0 && (
                      <tr className="no-data-table-row">
                        <td colSpan={8}>
                          <div className="d-flex align-items-center justify-content-center no-data-table-content">
                            <p className="">No Data Found!</p>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          <Pagination
            maxLastLength={limit}
            array={apiData}
            HandlepageChange={HandlepageChange}
            pageNumber={pageNumber}
          />
        </div>

        {/* AreYouSureModal */}
        {isAreYouSureModalOpen && (
          <AreYouSureModal
            open={isAreYouSureModalOpen}
            setOpen={setIsAreYouSureModalOpen}
            label={"Are You Sure?"}
            message={`Do you really want to ${
              deletingId?.isActive == "active" ? "Activate" : "Deactivate"
            } this company?`}
            handleYes={deleteItemHandler}
            isLoading={isDeleteApiCall}
          />
        )}

        {isAddAndEditModalOpen && (
          <AddUnclaimedCompaniesModal
            open={isAddAndEditModalOpen}
            setOpen={setIsAddAndEditModalOpen}
            handleSubmit={addCompaniesHndler}
            isLoading={isAddingCompanies}
          />
        )}

        {detail?.isModalOpen && (
          <UserDetailModal
            open={detail?.isModalOpen}
            setOpen={() => setDetail((p) => ({ ...p, isModalOpen: false }))}
            data={detail?.data}
          />
        )}
      </section>
    </ScreenBoiler>
  );
}

export default UsersScreen;
