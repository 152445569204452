import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Post, Delete, Patch, Get } from "../Axios/AxiosFunctions";
import Pagination from "../components/Pagination";
import TableRowsLoader from "../components/TableRowsLoader";
import moment from "moment";
import { BaseURL, apiHeader, recordsLimit, imageUrl } from "../Config/apiUrl";
import { Button } from "react-bootstrap";
import ScreenBoiler from "../components/ScreenBoiler";
import QueriesModal from "../components/QueriesModal";

function Queries() {
  const [limit, setLimit] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const accessToken = useSelector((state) => state.authReducer.access_token);
  console.log(accessToken , "tokensasasas/???")
  const [apiData, setApiData] = useState([]);
  const [isGetting, setIsGetting] = useState(false);

  const [isAddAndEditModalOpen, setIsAddAndEditModalOpen] = useState(false);
  const [isAddEditApiCall, setIsAddEditApiCall] = useState(false);
  const [editingData, setEditingData] = useState(null);
  const [message, setMessage] = useState("");

  const [search, setSearch] = useState("all");
  // get Item handler
  const getData = async (pageNo, newLimit) => {
    const url = BaseURL(`queries/admin?status=${search}`);

    setIsGetting(true);
    const response = await Get(url, accessToken);
    setIsGetting(false);
    if (response !== undefined) {
      setApiData(response?.data?.data);
    }
  };

  // fire on component mount
  useEffect(() => {
    getData();
  }, [search]);

  // seen

  const handleSeen = async (id, data) => {
    const url = BaseURL(`queries/admin/update`);
    setIsGetting(true);
    const body = {
      queryId: id,
      status: "seen",
    };
    const response = await Patch(url, body, apiHeader(accessToken));
    setIsGetting(false);
    if (response !== undefined) {
        alert("success")
        getData()
    }
  };

//   handleRejected
  const handleRejected = async (id, data) => {
    const url = BaseURL(`queries/admin/update`);
    setIsGetting(true);
    const body = {
      queryId: id,
      status: "rejected",
    };
    const response = await Patch(url, body, apiHeader(accessToken));
    setIsGetting(false);
    if (response !== undefined) {
        getData()
    }
  };

  // pagination handler
  const HandlepageChange = (value) => {
    const newPageNumber = pageNumber + value;
    setPageNumber(newPageNumber);
    getData(newPageNumber, limit);
  };

  return (
    <ScreenBoiler>
      <section className="reported-ads mt-5">
        <div className="fieldSetHeaderContainer d-flex flex-column">
          <h1>Queries</h1>
        </div>

        <div className="fieldSetMainContainer">
          <div className="place-add-btn-container mb-4">
            <div className="d-flex align-items-center">
              <p className="m-0 me-3 fw-bold">Records Per Page:</p>
              <select
                value={limit}
                onChange={(e) => {
                  setLimit(e.target.value);
                  setPageNumber(1);
                  HandlepageChange(1, e.target.value);
                }}
                className="px-3 py-2 custom-selection"
              >
                <option value={50} className="pt-2">
                  50
                </option>
                <option value={150}>150</option>
                <option value={500}>500</option>
              </select>
            </div>

            <div className="d-flex align-items-center">
              <p className="m-0 me-3 fw-bold">View:</p>
              <select
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                //   setPageNumber(1);
                //   getData(1, limit, e.target.value);
                }}
                className="px-3 py-2 custom-selection"
              >
                <option  value={"all"}>All Queries</option>
                <option  value={"seen"}>Seen</option>
                <option  value={"pending"}>Pending</option>
              </select>
            </div>
          </div>

          {/* Table */}
          {isGetting ? (
            <TableRowsLoader />
          ) : (
            <div className="col-md-12 table_recent">
              <div className="table-responsive">
                <table
                  className="table"
                  id="dataTable"
                  width="100%"
                  cellspacing="0"
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Contact</th>
                      <th>Email</th>
                      <th>Services</th>
                      <th>Status</th>
                      <th>Last Updated</th>
                      <th>Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    {apiData?.map((item, index) => {
                      return (
                        <tr key={item?._id}>
                          <td>{++index}</td>
                          <td className="t-t-c">
                            {item?.firstName} {item?.lastName}
                          </td>
                          <td>{item?.contact}</td>
                          <td>{item?.email}</td>
                          <td>
                            {item?.services
                              ?.map(({ name }) => name)
                              ?.join(", ") || "—"}
                          </td>
                          <td>{item?.status}</td>
                          <td>
                            {moment(item?.updatedAt).format(
                              "ddd, DD MMM YYYY hh:mm A"
                            )}
                          </td>
                          <td>
                            <ul className="d-flex">
                              <li
                                className="page-item align-self-end me-2"
                                onClick={() => {
                                  setEditingData(item);
                                  setIsAddAndEditModalOpen(true);
                                  setMessage(item?.message);
                                }}
                              >
                                <Button className="update-btn">View</Button>
                              </li>
                              {item?.status == "pending" &&
                              <li
                                onClick={() => {
                                  handleSeen(item?._id, item?.status);
                                }}
                                className="page-item align-self-end ml-4 ms-2"
                              >
                                <Button className="update-btn w-120px">
                                  Seen
                                </Button>
                              </li>
                              }

{item?.status == "seen" && 
                              <li
                                onClick={() => {
                                  handleRejected(item?._id, item?.status);
                                }}
                                className="page-item align-self-end ml-4 ms-2"
                              >
                                <Button className="update-btn w-120px">
                                  Rejected
                                </Button>
                              </li>
                              }
                              {/* <li
                                className="page-item align-self-end ml-4"
                                onClick={() => {
                                  setDeletingId(item?._id);
                                  setIsAreYouSureModalOpen(true);
                                }}
                              >
                                <Button className="delete-btn">Delete</Button>
                              </li> */}
                            </ul>
                          </td>
                        </tr>
                      );
                    })}

                    {apiData?.length == 0 && (
                      <tr className="no-data-table-row">
                        <td colSpan={8}>
                          <div className="d-flex align-items-center justify-content-center no-data-table-content">
                            <p className="">No Data Found!</p>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          <Pagination
            maxLastLength={limit}
            array={apiData}
            HandlepageChange={HandlepageChange}
            pageNumber={pageNumber}
          />
        </div>

        {isAddAndEditModalOpen && (
          <QueriesModal
            open={isAddAndEditModalOpen}
            setOpen={setIsAddAndEditModalOpen}
            data={message}
            isLoading={isAddEditApiCall}
          />
        )}
      </section>
    </ScreenBoiler>
  );
}

export default Queries;
