import React, { useEffect, useState } from "react";
import Quill from "react-quill";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import MuiButton from "@mui/material/Button";
import { Delete, Get, Patch, Post } from "../Axios/AxiosFunctions";
import AddImageComponent from "../components/AddImageComponent";
import { BaseURL, apiHeader, urlToObject, imageUrl } from "../Config/apiUrl";
import { toast } from "react-toastify";
import BeforeLoadContentLoader from "../components/BeforeLoadContentLoader";
import ScreenBoiler from "../components/ScreenBoiler";

const ListingDetailsCms = () => {
  const pageData = useLocation()?.state?.item;
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const navigate = useNavigate();
  const [isApiCall, setIsApiCall] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pageName] = useState(pageData?.pageName);
  const [pageId] = useState(pageData?._id);

  // Listing Details Section States Start //

  const [section1Title, setSection1Title] = useState("");
  const [section1Description, setSection1Description] = useState({});

  const [section2Title, setSection2Title] = useState("");
  const [section2Description, setSection2Description] = useState({});

  const [section3Title, setSection3Title] = useState("");
  const [section3Description, setSection3Description] = useState({});

  const [section4Title, setSection4Title] = useState("");

  const [section5Title, setSection5Title] = useState("");
  const [section5SubTitle1, setSection5SubTitle1] = useState("");
  const [section5SubDescription1, setSection5SubDescription1] = useState("");

  const [section5SubTitle2, setSection5SubTitle2] = useState("");
  const [section5SubDescription2, setSection5SubDescription2] = useState("");

  const [section6Title, setSection6Title] = useState("");

  const [section1Image, setSection1Image] = useState({});
  const [section3Image, setSection3Image] = useState({});
  const [section5SubIcon1, setSection5SubIcon1] = useState("");
  const [section5SubIcon2, setSection5SubIcon2] = useState("");
  const [section6Image1, setSection6Image1] = useState("");
  const [section6Image2, setSection6Image2] = useState("");
  const [section6Image3, setSection6Image3] = useState("");
  const [section6Image4, setSection6Image4] = useState("");
  const [section6Image5, setSection6Image5] = useState("");
  const [section6Image6, setSection6Image6] = useState("");

  // Lisitng Details Section States End //

  const getData = async () => {
    const url = BaseURL(`cms/page/all/new?pages=${pageData?.pageName}`);
    setIsApiCall(true);
    const response = await Get(url, accessToken);
    if (response !== undefined) {
      setSection1Title(response?.data?.data?.section1_title);
      setSection1Description(response?.data?.data?.section1_description);

      setSection2Title(response?.data?.data?.section2_title);
      setSection2Description(response?.data?.data?.section2_description);

      setSection3Title(response?.data?.data?.section3_title);
      setSection3Description(response?.data?.data?.section3_description);

      setSection4Title(response?.data?.data?.section4_title);
      setSection5Title(response?.data?.data?.section5_title);

      setSection5SubTitle1(response?.data?.data?.section5_sub_title1);
      setSection5SubDescription1(
        response?.data?.data?.section5_sub_description1
      );

      setSection5SubTitle2(response?.data?.data?.section5_sub_title2);
      setSection5SubDescription2(
        response?.data?.data?.section5_sub_description2
      );

      setSection6Title(response?.data?.data?.section6_title);

      //   Single Image
      const section1Image = await urlToObject(
        imageUrl(response?.data?.data?.section1_image)
      );

      setSection1Image(section1Image);

      const section3Image = await urlToObject(
        imageUrl(response?.data?.data?.section3_image)
      );

      setSection3Image(section3Image);

      const section5SubIcon1 = await urlToObject(
        imageUrl(response?.data?.data?.section5_sub_icon1)
      );

      setSection5SubIcon1(section5SubIcon1);

      const section5SubIcon2 = await urlToObject(
        imageUrl(response?.data?.data?.section5_sub_icon2)
      );

      setSection5SubIcon2(section5SubIcon2);

      const section6Image1 = await urlToObject(
        imageUrl(response?.data?.data?.section6_image1)
      );

      setSection6Image1(section6Image1);

      const section6Image2 = await urlToObject(
        imageUrl(response?.data?.data?.section6_image2)
      );

      setSection6Image2(section6Image2);

      const section6Image3 = await urlToObject(
        imageUrl(response?.data?.data?.section6_image3)
      );

      setSection6Image3(section6Image3);

      const section6Image4 = await urlToObject(
        imageUrl(response?.data?.data?.section6_image4)
      );

      setSection6Image4(section6Image4);

      //   Single Image\
      const section6Image5 = await urlToObject(
        imageUrl(response?.data?.data?.section6_image5)
      );
      setSection6Image5(section6Image5);

      const section6Image6 = await urlToObject(
        imageUrl(response?.data?.data?.section6_image6)
      );
      setSection6Image6(section6Image6);
    }
    setIsApiCall(false);

  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    let timeOut = setTimeout(() => {
      setIsLoading(false);
    }, 2800);
    return () => clearTimeout(timeOut);
  }, []);

  //   handleSubmit
  const handleSubmit = async () => {
    const params = {
      _id: pageId,
      pageName,

      section1_title: section1Title,
      section1_description: section1Description,

      section2_title: section2Title,
      section2_description: section2Description,

      section3_title: section3Title,
      section3_description: section3Description,

      section4_title: section4Title,

      section5_title: section5Title,
      section5_sub_title1: section5SubTitle1,
      section5_sub_description1: section5SubDescription1,

      section5_sub_title2: section5SubTitle2,
      section5_sub_description2: section5SubDescription2,

      section6_title: section6Title,

      section1_image: section1Image,
      section3_image: section3Image,
      section5_sub_icon1: section5SubIcon1,
      section5_sub_icon2: section5SubIcon2,
      section6_image1: section6Image1,
      section6_image2: section6Image2,
      section6_image3: section6Image3,
      section6_image4: section6Image4,
      section6_image5: section6Image5,
      section6_image6: section6Image6,
    };

    const formData = new FormData();
    for (let key in params) {
      params[key] && formData.append(key, params[key]);
    }

    const url = BaseURL("cms/page/update");
    setIsApiCall(true);
    const response = await Patch(url, formData, apiHeader(accessToken, true));
    setIsApiCall(false);

    if (response !== undefined) {
      toast.success("Page data updated successfully");
      getData();
    }
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <ScreenBoiler>
      <>
        <section
          style={{ visibility: isApiCall && "hidden" }}
          className="home-section"
        >
          <div style={{ textAlign: "end" }} className="homepage_heading">
            <MuiButton
              onClick={() => navigate(-1)}
              variant="contained"
              color="primary"
              className="mt-3 custom-btn rounded-lg radius-8 mb-4"
            >
              Go Back
            </MuiButton>
          </div>
          <div className="homepage_heading">
            <h1>{pageName?.toUpperCase()} PAGE DETAILS</h1>
          </div>

          {isApiCall && <BeforeLoadContentLoader />}

          <div className="homeSection1">
            <p className="mb-0 mt-3">Section 1 Title</p>
            <input
              className="admin_Input_cms"
              type="text"
              name="section1 title"
              value={section1Title}
              onChange={(e) => setSection1Title(e.target.value)}
            />
            <p className="mb-0 mt-3">Section 1 description</p>
            <textarea
              className="admin_Input_cms"
              rows="4"
              cols="50"
              value={section1Description}
              onChange={(e) => setSection1Description(e.target.value)}
            ></textarea>
            <p className="mb-3 mt-3">Section 1 Image</p>
            <div className="section1-img-box">
              <AddImageComponent
                index={null}
                item={section1Image}
                onSubmit={(data) => setSection1Image(data)}
                initialState={section1Image}
              />
            </div>
          </div>

          <div className="homeSection2">
            <p className="mb-0 mt-3">Section 2 Title</p>
            <input
              className="admin_Input_cms"
              type="text"
              name="section2 title"
              value={section2Title}
              onChange={(e) => setSection2Title(e.target.value)}
            />
            <p className="mb-0 mt-3">Section 2 description</p>
            <textarea
              className="admin_Input_cms"
              rows="4"
              cols="50"
              value={section2Description}
              onChange={(e) => setSection2Description(e.target.value)}
            ></textarea>
          </div>

          <div className="homeSection3">
            <p className="mb-0 mt-3">Section 3 Title</p>
            <input
              className="admin_Input_cms"
              type="text"
              name="section3 title"
              value={section3Title}
              onChange={(e) => setSection3Title(e.target.value)}
            />
            <p className="mb-0 mt-3">Section 3 Description</p>
            <textarea
              className="admin_Input_cms"
              rows="4"
              cols="50"
              value={section3Description}
              onChange={(e) => setSection3Description(e.target.value)}
            ></textarea>
            <p className="mb-3 mt-3">Section 3 Image</p>
            <div className="section1-img-box">
              <AddImageComponent
                index={null}
                item={section3Image}
                onSubmit={(data) => setSection3Image(data)}
                initialState={section3Image}
              />
            </div>
          </div>

          <div className="homeSection4">
            <p className="mb-0 mt-3">Section 4 Title</p>
            <input
              className="admin_Input_cms"
              type="text"
              name="section4 title"
              value={section4Title}
              onChange={(e) => setSection4Title(e.target.value)}
            />
          </div>

          <div className="homeSection5">
            <p className="mb-0 mt-3">Section 5 SubTitle 1</p>
            <textarea
              className="admin_Input_cms"
              rows="4"
              cols="50"
              value={section5SubTitle1}
              onChange={(e) => setSection5SubTitle1(e.target.value)}
            ></textarea>
            <p className="mb-0 mt-3">Section 5 SubDescription 1</p>
            <textarea
              className="admin_Input_cms"
              rows="4"
              cols="50"
              value={section5SubDescription1}
              onChange={(e) => setSection5SubDescription1(e.target.value)}
            ></textarea>
            <p className="mb-3 mt-3">Section 5 SubIcon 1</p>
            <div className="section1-img-box">
              <AddImageComponent
                index={null}
                item={section5SubIcon1}
                onSubmit={(data) => setSection5SubIcon1(data)}
                initialState={section5SubIcon1}
              />
            </div>
            <p className="mb-0 mt-3">Section 5 SubTitle 2</p>
            <textarea
              className="admin_Input_cms"
              rows="4"
              cols="50"
              value={section5SubTitle2}
              onChange={(e) => setSection5SubTitle1(e.target.value)}
            ></textarea>
            <p className="mb-0 mt-3">Section 5 SubDescription 2</p>
            <textarea
              className="admin_Input_cms"
              rows="4"
              cols="50"
              value={section5SubDescription2}
              onChange={(e) => setSection5SubDescription1(e.target.value)}
            ></textarea>
            <p className="mb-3 mt-3">Section 5 SubIcon 2</p>
            <div className="section1-img-box">
              <AddImageComponent
                index={null}
                item={section5SubIcon2}
                onSubmit={(data) => setSection5SubIcon2(data)}
                initialState={section5SubIcon2}
              />
            </div>
          </div>

          <div className="homeSection6">
            <p className="mb-0 mt-3">Section 6 Title</p>
            <textarea
              className="admin_Input_cms"
              rows="4"
              cols="50"
              value={section6Title}
              onChange={(e) => setSection6Title(e.target.value)}
            ></textarea>
            <p className="mb-3 mt-3">Section 6 Image 1</p>
            <div className="section1-img-box">
              <AddImageComponent
                index={null}
                item={section6Image1}
                onSubmit={(data) => setSection6Image1(data)}
                initialState={section6Image1}
              />
            </div>
            <p className="mb-3 mt-3">Section 6 Image 2</p>
            <div className="section1-img-box">
              <AddImageComponent
                index={null}
                item={section6Image2}
                onSubmit={(data) => setSection6Image2(data)}
                initialState={section6Image2}
              />
            </div>
            <p className="mb-3 mt-3">Section 6 Image 3</p>
            <div className="section1-img-box">
              <AddImageComponent
                index={null}
                item={section6Image3}
                onSubmit={(data) => setSection6Image3(data)}
                initialState={section6Image3}
              />
            </div>
            <p className="mb-3 mt-3">Section 6 Image 4</p>
            <div className="section1-img-box">
              <AddImageComponent
                index={null}
                item={section6Image4}
                onSubmit={(data) => setSection6Image4(data)}
                initialState={section6Image4}
              />
            </div>
            <p className="mb-3 mt-3">Section 6 Image 5</p>
            <div className="section1-img-box">
              <AddImageComponent
                index={null}
                item={section6Image5}
                onSubmit={(data) => setSection6Image5(data)}
                initialState={section6Image5}
              />
            </div>
            <p className="mb-3 mt-3">Section 6 Image 6</p>
            <div className="section1-img-box">
              <AddImageComponent
                index={null}
                item={section6Image6}
                onSubmit={(data) => setSection6Image6(data)}
                initialState={section6Image6}
              />
            </div>
          </div>
          <div className="home-cms-btn mt-3">
            <MuiButton
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              className="mt-3 custom-btn rounded-lg radius-8 ms-4"
              disabled={isApiCall}
            >
              {isApiCall ? "Updating..." : "Update"}
            </MuiButton>
          </div>
        </section>
      </>
    </ScreenBoiler>
  );
};

export default ListingDetailsCms;
