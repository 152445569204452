import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Get } from "../Axios/AxiosFunctions";
import TableRowsLoader from "../components/TableRowsLoader";
import { BaseURL } from "../Config/apiUrl";
import ScreenBoiler from "../components/ScreenBoiler";
import { useParams } from "react-router-dom";
import UIList from "../components/UIList";
import moment from "moment";
import VillaIcon from "@mui/icons-material/Villa";
import InventoryIcon from "@mui/icons-material/Inventory";
import GolfCourseIcon from "@mui/icons-material/GolfCourse";
import InnerUIList from "../components/InnerUIList";
import ExpandWrapper from "../components/ExpandWrapper";

function DetailScreen() {
  const params = useParams();
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [apiData, setApiData] = useState([]);
  const [isGetting, setIsGetting] = useState(false);

  // get Item handler
  const getData = async () => {
    const url = BaseURL(`package/admin/${params.id}`);

    setIsGetting(true);
    const response = await Get(url, accessToken);
    setIsGetting(false);
    if (response !== undefined) {
      setApiData(response?.data?.data);
    }
  };

  // fire on component mount
  useEffect(() => {
    getData();
  }, []);

  return (
    <ScreenBoiler>
      <section className="reported-ads mt-5">
        {isGetting && <TableRowsLoader />}
        {!isGetting && (
          <>
            <div className="fieldSetHeaderContainer d-flex flex-column">
              <h1>The Big Picture</h1>
            </div>
            <div className="fieldSetMainContainer">
              {/* States */}
              <h3 className="mt-3">States</h3>
              {apiData?.state?.map((data, i) => (
                <UIList
                  key={i}
                  t1={`State Name: ${data?.state}`}
                  t2={`Contain Security Questions: ${
                    data?.securityQuestions ? "Yes" : "No"
                  }`}
                  d1={`Passing Criteria: ${data?.passingCriteria}%`}
                  d2={`Last Updated: ${moment(data?.updatedAt).format("lll")}`}
                  Icon={VillaIcon}
                />
              ))}
              {apiData?.state?.length === 0 && (
                <div className="d-flex align-items-center justify-content-center">
                  <p className="">No state Added!</p>
                </div>
              )}

              {/* Package */}
              <h3 className="mt-3">Package</h3>
              <UIList
                t1={`Package Type: ${apiData?.type}`}
                d1={`Package Price: $${parseFloat(apiData?.price).toFixed(2)}`}
                t2={`Active: ${apiData?.isActive ? "Yes" : "No"}`}
                Icon={InventoryIcon}
                description={apiData?.description}
                expandedHeading="Description"
                expand
              />

              {/* Courses */}
              <ExpandWrapper>
                <h3 className="mt-3">Courses</h3>
                {apiData?.courses?.map((data, i) => (
                  <>
                    <UIList
                      key={i}
                      t1={`Course Name: ${data?.courseName}`}
                      d1={`Last Updated: ${moment(data?.updatedAt).format(
                        "lll"
                      )}`}
                      t2={`Active: ${apiData?.isActive ? "Yes" : "No"}`}
                      Icon={GolfCourseIcon}
                    />
                    {/* Lesson */}
                    <InnerUIList data={data} />
                  </>
                ))}
                {apiData?.courses?.length === 0 && (
                  <div className="d-flex align-items-center justify-content-center">
                    <p className="">No Course Added!</p>
                  </div>
                )}
              </ExpandWrapper>
            </div>
          </>
        )}
      </section>
    </ScreenBoiler>
  );
}

export default DetailScreen;
