import React, { useEffect, useState } from "react";
import Quill from "react-quill";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import MuiButton from "@mui/material/Button";
import { Delete, Get, Patch, Post } from "../Axios/AxiosFunctions";
import AddImageComponent from "../components/AddImageComponent";
import { BaseURL, apiHeader, urlToObject, imageUrl } from "../Config/apiUrl";
import { toast } from "react-toastify";
import BeforeLoadContentLoader from "../components/BeforeLoadContentLoader";
import ScreenBoiler from "../components/ScreenBoiler";

const ListingCms = () => {
  const pageData = useLocation()?.state?.item;
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const navigate = useNavigate();
  const [isApiCall, setIsApiCall] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pageName] = useState(pageData?.pageName);
  const [pageId] = useState(pageData?._id);
  // Listing Section States Start //

  const [section1Image, setSection1Image] = useState({});
  const [section3Image, setSection3Image] = useState({});

  const [section1Title, setSection1Title] = useState("");
  const [section1Description, setSection1Description] = useState({});
  const [section2Title, setSection2Title] = useState("");
  const [section3Title, setSection3Title] = useState("");
  const [section3Description, setSection3Description] = useState({});

  // Listing Section States End //

  const getData = async () => {
    const url = BaseURL(`cms/page/all/new?pages=${pageData?.pageName}`);
    setIsApiCall(true);
    const response = await Get(url, accessToken);

    if (response !== undefined) {
      setSection1Title(response?.data?.data?.section1_title);
      setSection1Description(response?.data?.data?.section1_description);

      setSection2Title(response?.data?.data?.section2_title);

      setSection3Title(response?.data?.data?.section3_title);
      setSection3Description(response?.data?.data?.section3_description);

      //   Single Image
      const section1Image = await urlToObject(
        imageUrl(response?.data?.data?.section1_image)
      );

      setSection1Image(section1Image);

      const section3Image = await urlToObject(
        imageUrl(response?.data?.data?.section3_image)
      );

      setSection3Image(section3Image);
    }
    setIsApiCall(false);

  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    let timeOut = setTimeout(() => {
      setIsLoading(false);
    }, 2800);
    return () => clearTimeout(timeOut);
  }, []);

  //   handleSubmit
  const handleSubmit = async () => {
    const params = {
      _id: pageId,
      pageName,

      section1_title: section1Title,
      section1_description: section1Description,

      section2_title: section2Title,

      section3_title: section3Title,
      section3_description: section3Description,

      section1_image: section1Image,
      section3_image: section3Image,
    };

    const formData = new FormData();
    for (let key in params) {
      params[key] && formData.append(key, params[key]);
      console.log("params-->", params[key]);
    }

    const url = BaseURL("cms/page/update");
    setIsApiCall(true);
    const response = await Patch(url, formData, apiHeader(accessToken, true));
    setIsApiCall(false);

    if (response !== undefined) {
      toast.success("Page data updated successfully");
      getData();
    }
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <ScreenBoiler>
      <>
        <section 
        
        style={{ visibility: isApiCall && "hidden" }}
        
        className="home-section">
          <div style={{ textAlign: "end" }} className="homepage_heading">
            <MuiButton
              onClick={() => navigate(-1)}
              variant="contained"
              color="primary"
              className="mt-3 custom-btn rounded-lg radius-8 mb-4"
            >
              Go Back
            </MuiButton>
          </div>

          <div className="homepage_heading">
            <h1>{pageName?.toUpperCase()} PAGE DETAILS</h1>
          </div>

          {isApiCall && <BeforeLoadContentLoader />}

          <div className="homeSection1">
            <p className="mb-0 mt-3">Section 1 Title</p>
            {/* <input
              className="admin_Input_cms"
              type="text"
              name="section1 title"
              value={section1Title}
              onChange={(e) => setSection1Title(e.target.value)}
            /> */}
            <Quill
              theme="snow"
              value={section1Title}
              onChange={setSection1Title}
            />

            <p className="mb-0 mt-3">Section 1 Desctiption</p>
            <input
              className="admin_Input_cms"
              type="text"
              name="section1 description"
              value={section1Description}
              onChange={(e) => setSection1Description(e.target.value)}
            />
            <p className="mb-3 mt-3">Section 1 Image</p>
            <div className="section1-img-box">
              <AddImageComponent
                index={null}
                item={section1Image}
                onSubmit={(data) => setSection1Image(data)}
                initialState={section1Image}
              />
            </div>
          </div>

          <div className="homeSection2">
            <p className="mb-0 mt-3">Section 2 Title</p>
            <input
              className="admin_Input_cms"
              type="text"
              name="section2 title"
              value={section2Title}
              onChange={(e) => setSection2Title(e.target.value)}
            />
          </div>

          <div className="homeSection3">
            <p className="mb-0 mt-3">Section 3 Title</p>
            <input
              className="admin_Input_cms"
              type="text"
              name="section3 title"
              value={section3Title}
              onChange={(e) => setSection3Title(e.target.value)}
            />
            <p className="mb-0 mt-3">Section 3 Description</p>
            <textarea
              className="admin_Input_cms"
              rows="4"
              cols="50"
              value={section3Description}
              onChange={(e) => setSection3Description(e.target.value)}
            ></textarea>
            <p className="mb-3 mt-3">Section 3 Image</p>
            <div className="section1-img-box">
              <AddImageComponent
                index={null}
                item={section3Image}
                onSubmit={(data) => setSection3Image(data)}
                initialState={section3Image}
              />
            </div>
          </div>

          <div className="home-cms-btn mt-3">
            <MuiButton
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              className="mt-3 custom-btn rounded-lg radius-8 ms-4"
              disabled={isApiCall}
            >
              {isApiCall ? "Updating..." : "Update"}
            </MuiButton>
          </div>
        </section>
      </>
    </ScreenBoiler>
  );
};

export default ListingCms;
