import React, { useState } from "react";
import { toast } from "react-toastify";
import { FaFileCsv } from "react-icons/fa";

import { formRegEx, formRegExReplacer, imageUrl } from "../Config/apiUrl";
import ModalSkeleton from "./ModalSkeleton";
import { green } from "@material-ui/core/colors";

const AddUnclaimedCompaniesModal = ({
  open,
  setOpen,
  data,
  isLoading,
  handleSubmit,
}) => {
  // IMAGE / vIDEO
  const [selectedImage, setSelectedImage] = useState(data?.icon || undefined);
  const [selectedImageFile, setSelectedImageFile] = useState(null);

  //   btnClickHandler
  const btnClickHandler = (e) => {
    e.preventDefault();

    if (!selectedImageFile)
      return toast.warn("Please select a valid csv file to continue.");
    const params = {
      csv: selectedImageFile,
    };

    handleSubmit(params);
  };

  //   handleSelectImage
  const handleSelectImage = (event) => {
    setSelectedImageFile(event.target.files[0]);
    let reader = new FileReader();

    reader.onload = (event) => {
      setSelectedImage(event.target.result);

      var media = new Audio(reader.result);
      media.onloadedmetadata = function () {
        // setVideoDuration(millisToMinutesAndSeconds(media.duration * 1000));
      };
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  return (
    <ModalSkeleton open={open} setOpen={setOpen}>
      <form className="login100-form validate-form">
        <span className="login100-form-title text-left text-color pb-4">
          Add Companies
        </span>

        <p>
          {`Columns should be in this order => name, state, zipcode, description,
          uniqueCode`}
        </p>

        {/* Video */}
        <div class="form-group" className="seo__imagePicker mt-3">
          <input
            type="file"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            name="uploadfile"
            id="img"
            style={{
              display: "none",
            }}
            onChange={(event) => {
              handleSelectImage(event);
            }}
          />
          <label for="exampleInputEmail1">Select CSV</label>
          <label
            for="img"
            className="btn btn-primary btn-lg btn-block"
            style={{
              backgroundColor: green[500],
            }}
            onChange={(e) => {
              // console.log(e.target.files[0]);
            }}
          >
            {selectedImage ? "Change selection" : "Pick a CSV File"}
          </label>
        </div>

        {/* Shoe Image */}
        {selectedImage && (
          <div className="d-flex align-items-center justify-content-center w-100 mt-4">
            <FaFileCsv size={30} color={"var(--main-color)"} className="me-2" />{" "}
            File Selected
          </div>
        )}

        {/* btn */}
        <div className="container-login100-form-btn mt-4">
          <div className="wrap-login100-form-btn">
            <div className="login100-form-bgbtn"></div>
            <button
              disabled={isLoading}
              className="login100-form-btn"
              onClick={btnClickHandler}
            >
              {isLoading ? "Please Wait..." : "Submit"}
            </button>
          </div>
        </div>
      </form>
    </ModalSkeleton>
  );
};

export default AddUnclaimedCompaniesModal;
