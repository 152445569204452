import React, { useState } from "react";
import { toast } from "react-toastify";

// Radio imports
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import { formRegEx, formRegExReplacer } from "../Config/apiUrl";
import ModalSkeleton from "./ModalSkeleton";

const AddAndEditPackagesModalP = ({
  open,
  setOpen,
  data,
  isLoading,
  handleSubmit,
}) => {
  // states
  const [packageType, setPackageType] = useState(data?.packageType || "");
  const [price, setPrice] = useState(data?.price || "");
  const [order, setOrder] = useState(data?.order || "");
  const [bonusPercentage, setBonusPercentage] = useState(
    data?.bonusPercentage || ""
  );
  const [rechargeMessage, setRechargeMessage] = useState(
    data?.rechargeMessage || ""
  );

  //   btnClickHandler
  const btnClickHandler = (e) => {
    e.preventDefault();
    const params = {
      packageType,
      price: +price,
      bonusPercentage: Number(bonusPercentage),
      rechargeMessage: Number(rechargeMessage),
      order: Number(order),
    };

    // validate
    for (let key in params)
      if (params[key] === "")
        return toast.warn(
          `${key.replace(formRegEx, formRegExReplacer)} is required`
        );

    handleSubmit(params);
  };

  return (
    <ModalSkeleton open={open} setOpen={setOpen}>
      <form className="login100-form validate-form">
        <span className="login100-form-title text-left text-color pb-4">
          {data ? "Edit" : "Add"} Package
        </span>

        {/* Package Type */}
        <div>
          <label>Package Type</label>
          <input
            className="add-evidence-modal-input full"
            type="text"
            placeholder="Package Type Here"
            value={packageType}
            onChange={(e) => {
              setPackageType(e.target.value);
            }}
            disabled
          />
        </div>

        <div className="mt-3">
          <label>Order</label>
          <input
            className="add-evidence-modal-input full"
            type="number"
            min="1"
            placeholder="Order"
            value={order}
            onChange={(e) => {
              setOrder(e.target.value);
            }}
          />
        </div>
        <div className="mt-3">
          <label>Price</label>
          <input
            className="add-evidence-modal-input full"
            type="number"
            min="1"
            placeholder="Price"
            value={price}
            onChange={(e) => {
              setPrice(e.target.value);
            }}
          />
        </div>
        <div className="mt-3">
          <label>Bonus %</label>
          <input
            className="add-evidence-modal-input full"
            type="number"
            min="1"
            placeholder="Bonus %"
            value={bonusPercentage}
            onChange={(e) => {
              setBonusPercentage(e.target.value);
            }}
          />
        </div>
        <div className="mt-3">
          <label>Recharge Message</label>
          <input
            className="add-evidence-modal-input full"
            type="number"
            min="1"
            placeholder="Recharge Message"
            value={rechargeMessage}
            onChange={(e) => {
              setRechargeMessage(e.target.value);
            }}
          />
        </div>
        <div className="mt-3">
          <label>Total Units Purchased</label>
          <input
            disabled={true}
            className="add-evidence-modal-input full"
            type="number"
            min="1"
            placeholder="Recharge Message"
            value={Number(price) + Number(bonusPercentage)}
            // onChange={(e) => {
            //   setRechargeMessage(e.target.value);
            // }}
          />
        </div>

        {/* enquiryForm */}
        {/* <div className="mt-3">
          <label>Enquiry Form</label>
          <FormControl component="fieldset" className="d-b">
            <RadioGroup
              row
              aria-label="status"
              name="controlled-radio-buttons-group"
              value={enquiryForm}
              onChange={(e) => setEnquiryForm(e?.target?.value)}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label="Required"
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="Not required"
              />
            </RadioGroup>
          </FormControl>
        </div> */}

        {/* btn */}
        <div className="container-login100-form-btn mt-4">
          <div className="wrap-login100-form-btn">
            <div className="login100-form-bgbtn"></div>
            <button
              disabled={isLoading}
              className="login100-form-btn"
              onClick={btnClickHandler}
            >
              {isLoading ? "Please Wait..." : "Submit"}
            </button>
          </div>
        </div>
      </form>
    </ModalSkeleton>
  );
};

export default AddAndEditPackagesModalP;
