import { TextareaAutosize } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ScreenBoiler from "../components/ScreenBoiler";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { apiHeader, BaseURL } from "../Config/apiUrl";
import { toast } from "react-toastify";
import { Get, Patch } from "../Axios/AxiosFunctions";
import MuiButton from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

const Disclaimer = () => {
  const pageData = useLocation()?.state?.item;
  const navigate = useNavigate();

  const [value, setValue] = useState("");
  const [isApiCall, setIsApiCall] = useState(false);
  const [getApiCall, setGetApiCall] = useState(false);
  const accessToken = useSelector((state) => state.authReducer.access_token);

  const pageName = pageData?.pageName;
  const pageId = pageData?._id;
  const handleSubmit = async () => {
    const params = {
      _id: pageId,
      pageName,
      content: value,
    };
    const url = BaseURL("cms/page/update");
    setIsApiCall(true);
    const response = await Patch(url, params, apiHeader(accessToken));
    setIsApiCall(false);

    if (response !== undefined) {
      toast.success("Page data updated successfully");
    }
  };

  const getData = async () => {
    const url = BaseURL(`cms/page/all/new?pages=${pageData?.pageName}`);
    setGetApiCall(true);
    const response = await Get(url, accessToken);
    console.log("🚀  Disclaimer", response);
    setGetApiCall(false);
    if (response !== undefined) {
      setValue(response?.data?.data?.content);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <ScreenBoiler>
      <section className="reported-ads">
      <div
      style={{ textAlign: "end" }}
      
      className="homepage_heading">
          <MuiButton
            onClick={() => navigate(-1)}
            variant="contained"
            color="primary"

            className="mt-3 custom-btn rounded-lg radius-8 mb-4"
          >
            Go Back
          </MuiButton>
        </div>
        <div className="fieldSetHeaderContainer d-flex flex-column">
          <h1>Disclaimer</h1>
        </div>
        <div>
          {/* <TextareaAutosize
            className="textArea_cus_b"
            minRows={12}
            aria-label="maximum height"
            placeholder="Enter Disclaimer"
          /> */}
          <ReactQuill
            className="textArea_cus_b"
            theme="snow"
            value={value}
            onChange={setValue}
          />
        </div>
        <div
          style={{ width: "20%", margin: "auto" }}
          className="container-login100-form-btn mt-4"
        >
          <div className="wrap-login100-form-btn">
            <div className="login100-form-bgbtn"></div>
            <button
              onClick={handleSubmit}
              disabled={isApiCall}
              className="login100-form-btn"
            >
              {isApiCall ? "Please Wait..." : "Submit"}
            </button>
          </div>
        </div>
      </section>
    </ScreenBoiler>
  );
};

export default Disclaimer;
