import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import "../assets/Styles/Templates.css";
import { Get, Patch } from "../Axios/AxiosFunctions";
import ScreenBoiler from "../components/ScreenBoiler";
import TableRowsLoader from "../components/TableRowsLoader";
import { apiHeader, BaseURL } from "../Config/apiUrl";
import parse from "html-react-parser";
import TemplateEditModal from "../components/TemplateEditModal";
import { toast } from "react-toastify";
const Templates = () => {
  const token = useSelector((state) => state?.authReducer?.access_token);
  const [apiData, setApiData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isApiCall, setIsApiCall] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const [selectedIndex, setSelectedIndex] = useState();

  const [open, setOpen] = useState(false);
  const getData = async () => {
    const apiUrl = BaseURL(`email-templates/`);
    setIsLoading(true);
    const response = await Get(apiUrl, token);
    setIsLoading(false);
    if (response !== undefined) {
      setApiData(response?.data?.data);
    } else {
      setIsLoading(false);
    }
  };
  const handleEdit = async (mess , subject) => {
    const body = {
      templateId: selectedData?._id,
      key: selectedData?.key,
      message: mess,
      subject:subject,
    };
    for (var key in body) {
      if (body[key] == "" || body[key] == null) {
        return toast.error("No Accept empty fields");
      }
    }
    const apiUrl = BaseURL(`email-templates/update`);
    setIsApiCall(true);
    const response = await Patch(apiUrl, body, apiHeader(token));
    setIsApiCall(false);
    if (response !== undefined) {
      const dataCopy = [...apiData];
      const index = dataCopy.findIndex((item) => item._id === selectedData._id);
      dataCopy[index] = response?.data?.data;
      setApiData(dataCopy);

      toast.success("Successfully Update");
      setOpen(false);
    } else {
      setIsApiCall(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <ScreenBoiler>
        <section className="reported-ads mt-5">
          <div className="fieldSetHeaderContainer d-flex flex-column">
            <h1>Templates</h1>
          </div>
          <div className="fieldSetMainContainer mt-3">
            {/* Table */}
            {isLoading ? (
              <TableRowsLoader />
            ) : (
              <div className="col-md-12 table_recent">
                <div className="table-responsive">
                  <table
                    className="table"
                    id="dataTable"
                    width="100%"
                    cellspacing="0"
                  >
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Key</th>
                        <th>Subject</th>
                        <th>Message</th>
                        <th>Actions</th>
                      </tr>
                    </thead>

                    <tbody>
                      {apiData?.map((item, index) => {
                        return (
                          <tr key={item?._id}>
                            <td>{++index}</td>
                            <td className="t-t-c">{item?.publicKey}</td>
                            <td className="t-t-c">{item?.subject}</td>
                            <td className="t-t-c">
                              {item?.message && parse(item?.message)}
                            </td>
                            <td className="t-t-c">
                              <Button
                                onClick={() => {
                                  setOpen(true);
                                  setSelectedData(item);
                                  setSelectedIndex(index);
                                }}
                              >
                                Edit
                              </Button>
                            </td>
                          </tr>
                        );
                      })}

                      {apiData?.length == 0 && (
                        <tr className="no-data-table-row">
                          <td colSpan={8}>
                            <div className="d-flex align-items-center justify-content-center no-data-table-content">
                              <p className="">No Data Found!</p>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </section>
      </ScreenBoiler>

     {open && <TemplateEditModal
        handleEdit={handleEdit}
        setOpen={setOpen}
        open={open}
        selectedData={selectedData}
        isApiCall={isApiCall}
      />}
    </>
  );
};

export default Templates;
