import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Patch, Get } from "../Axios/AxiosFunctions";
import Pagination from "../components/Pagination";
import TableRowsLoader from "../components/TableRowsLoader";
import moment from "moment";
import { BaseURL, apiHeader, recordsLimit } from "../Config/apiUrl";
import { Button } from "react-bootstrap";
import AreYouSureModal from "../components/AreYouSureModal";
import { toast } from "react-toastify";
import ScreenBoiler from "../components/ScreenBoiler";
import ShowMoreShowLessText from "../components/ShowMoreShowLessText";

function ReAttemptionRequests() {
  const [limit, setLimit] = useState(recordsLimit);
  const [pageNumber, setPageNumber] = useState(1);
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [apiData, setApiData] = useState([]);
  const [isGetting, setIsGetting] = useState(false);
  const [status, setStatus] = useState("pending");

  const [isAreYouSureModalOpen, setIsAreYouSureModalOpen] = useState(false);
  const [isDeleteApiCall, setIsDeleteApiCall] = useState(false);
  const [adminResponse, setAdminResponse] = useState({
    message: "",
    courseId: "",
    userId: "",
    status: "",
    index: 0,
  });

  // get Item handler
  const getData = async (pageNo, newLimit, status) => {
    const url = BaseURL(
      `exam/get-reattemption-requests?page=${pageNo}&limit=${newLimit}&status=${status}`
    );

    setIsGetting(true);
    const response = await Get(url, accessToken);
    setIsGetting(false);
    if (response !== undefined) {
      setApiData(response?.data?.data);
    }
  };

  // adminResponseApiCall
  const adminResponseApiCall = async () => {
    const url = BaseURL(`exam/accept-or-reject-reattempt-exam-request`);
    const params = {
      ...adminResponse,
    };

    setIsDeleteApiCall(true);
    const response = await Patch(url, params, apiHeader(accessToken));
    setIsDeleteApiCall(false);

    if (response !== undefined) {
      const dataCopy = [...apiData];
      const index = adminResponse?.index;
      dataCopy.splice(index, 1);
      setApiData(dataCopy);
      setIsAreYouSureModalOpen(false);
      toast.success(
        `Reattemption request ${adminResponse?.status} successfully`
      );
    }
  };

  // fire on component mount
  useEffect(() => {
    getData(1, limit, status);
  }, []);

  // pagination handler
  const HandlepageChange = (value) => {
    const newPageNumber = pageNumber + value;
    setPageNumber(newPageNumber);
    getData(newPageNumber, limit, status);
  };

  return (
    <ScreenBoiler>
      <section className="reported-ads mt-5">
        <div className="fieldSetHeaderContainer d-flex flex-column">
          <h1>Re-attemption Requests</h1>
        </div>

        <div className="fieldSetMainContainer">
          <div className="place-add-btn-container mb-4">
            <div className="d-flex align-items-center">
              <p className="m-0 me-3 fw-bold">Records Per Page:</p>
              <select
                value={limit}
                onChange={(e) => {
                  setLimit(e.target.value);
                  setPageNumber(1);
                  HandlepageChange(1, e.target.value);
                }}
                className="px-3 py-2 custom-selection"
              >
                <option value={10} className="pt-2">
                  10
                </option>
                <option value={25}>25</option>
                <option value={50}>50</option>
              </select>
            </div>
            <select
              value={status}
              onChange={(e) => {
                setStatus(e.target.value);
                setPageNumber(1);
                getData(1, limit, e?.target?.value);
              }}
              className="px-3 py-2 custom-selection"
            >
              <option value={"pending"} className="pt-2">
                Pending
              </option>
              <option value={"rejected"}>Rejected</option>
            </select>
          </div>

          {/* Table */}
          {isGetting ? (
            <TableRowsLoader />
          ) : (
            <div className="col-md-12 table_recent">
              <div className="table-responsive">
                <table
                  className="table"
                  id="dataTable"
                  width="100%"
                  cellspacing="0"
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Email</th>
                      <th>Name</th>
                      <th>State</th>
                      <th>Package</th>
                      <th>User Message</th>
                      <th>Requested At</th>
                      <th>Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    {apiData?.map((item, index) => {
                      return (
                        <tr key={item?._id}>
                          <td>{++index}</td>
                          <td className="t-t-c">{item?.email}</td>
                          <td className="t-t-c">{item?.name}</td>
                          <td>{item?.state?.state}</td>
                          <td className="t-t-c">{item?.package?.type}</td>
                          <td>
                            <ShowMoreShowLessText
                              text={item?.requestObj?.reAttemptionReason}
                            />
                          </td>
                          <td>
                            {moment(
                              item?.requestObj?.reAttemptionRequestedAt
                            ).format("ddd, DD MMM YYYY hh:mm A")}
                          </td>
                          <td>
                            <ul className="d-flex">
                              <li
                                className="page-item align-self-end me-2"
                                onClick={() => {
                                  setAdminResponse({
                                    message:
                                      "Do you want to approve this request?",
                                    courseId: item?.requestObj?.course,
                                    userId: item?._id,
                                    status: "accepted",
                                    index: index - 1,
                                  });
                                  setIsAreYouSureModalOpen(true);
                                }}
                              >
                                <Button className="update-btn">Accept</Button>
                              </li>
                              {status === "pending" && (
                                <li
                                  className="page-item align-self-end ml-4"
                                  onClick={() => {
                                    setAdminResponse({
                                      message:
                                        "Do you want to reject this request?",
                                      courseId: item?.requestObj?.course,
                                      userId: item?._id,
                                      status: "rejected",
                                      index: index - 1,
                                    });
                                    setIsAreYouSureModalOpen(true);
                                  }}
                                >
                                  <Button className="delete-btn">Reject</Button>
                                </li>
                              )}
                            </ul>
                          </td>
                        </tr>
                      );
                    })}

                    {apiData?.length == 0 && (
                      <tr className="no-data-table-row">
                        <td colSpan={8}>
                          <div className="d-flex align-items-center justify-content-center no-data-table-content">
                            <p className="">No Data Found!</p>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          <Pagination
            maxLastLength={limit}
            array={apiData}
            HandlepageChange={HandlepageChange}
            pageNumber={pageNumber}
          />
        </div>

        {/* AreYouSureModal */}
        {isAreYouSureModalOpen && (
          <AreYouSureModal
            open={isAreYouSureModalOpen}
            setOpen={setIsAreYouSureModalOpen}
            label={"Are You Sure?"}
            message={adminResponse?.message}
            handleYes={adminResponseApiCall}
            isLoading={isDeleteApiCall}
          />
        )}
      </section>
    </ScreenBoiler>
  );
}

export default ReAttemptionRequests;
