import React, { useEffect, useRef, useState } from "react";
import { AiOutlineCamera } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import { BiAddToQueue } from "react-icons/bi";
import { GrDocumentPdf } from "react-icons/gr";
import { toast } from "react-toastify";
import { imageUrl, pdfUrlUrl } from "../Config/apiUrl";

const AddImageComponent = ({
  index,
  item,
  onSubmit,
  initialState,
  isAddEmpty,
  fileTypeArray = ["image/jpeg", "image/png", "image/jpg"],
  fileType = "image",
  setOldPdf,
  oldPdf,
}) => {
  const inputRef = useRef(null);
  const [imageArray, setImageArray] = useState(initialState || [{}, {}, {}]);
  const [image, setImage] = useState({});

  useEffect(() => {
    setImageArray(initialState);
  }, [initialState]);

  function getImage() {
    inputRef.current.click();
  }

  const handleImageSubmit = (e) => {
    if (!e.target.files[0]) return;
    if (!fileTypeArray.includes(e.target.files[0].type)) {
      return toast.warn(`Please select a valid ${fileType} file`);
    }
    let newData;
    if (index == null) {
      newData = e.target.files[0];
      setImage(e.target.files[0]);
    } else {
      newData = imageArray.slice();
      newData.splice(index, 1, e.target.files[0]);
      setImageArray(newData);
    }
    onSubmit(newData);
  };

  const handleDeleteImage = () => {
    let newData;
    if (index == null) {
      newData = {};
      setImage({});
    } else {
      newData = imageArray.slice();
      newData.splice(index, 1);
      setImageArray(newData);

      // old Data
      setOldPdf((p) => p.filter((e) => e !== item));
    }
    onSubmit(newData);
  };

  const handleAddNewImage = () => {
    let newData;
    if (index == null && isAddEmpty == false) {
      newData = {};
      setImage({});
    } else {
      newData = imageArray.slice();
      newData.push({});
      setImageArray(newData);
    }
    onSubmit(newData);
  };

  return (
    <>
      {isAddEmpty == true ? (
        <div
          className="custom-image-upload  mb-3 d-flex justify-content-center align-items-center"
          onClick={() => handleAddNewImage()}
        >
          <div className="text-center">
            <BiAddToQueue size={25} />
          </div>
        </div>
      ) : item?.name ? (
        <div
          className="custom-image-upload position-relative  mb-2"
          onClick={() => getImage()}
        >
          <IoMdClose
            size={15}
            className="position-absolute btn-color"
            onClick={() => {
              handleDeleteImage();
            }}
            style={{
              top: "5px",
              right: "5px",
              borderRadius: "3px",
              ...(fileType == "pdf" && { color: "black !important" }),
              ...(fileType == "image" && { color: "white !important" }),
            }}
          />
          {fileType == "image" && (
            <img src={URL.createObjectURL(item)} className="w-100 h-100" />
          )}
          {fileType == "pdf" && (
            <p
              className="file-pdf-select c-p"
              onClick={(e) => {
                // event priority handler
                if (!e) {
                  e = window.event;
                }
                e.stopPropagation();
                window.open(URL.createObjectURL(item), "_blank");
              }}
            >{`${
              item?.name?.length > 35
                ? item?.name?.substring(0, 35) + "...pdf"
                : item?.name
            }`}</p>
          )}
        </div>
      ) : typeof item == "string" ? (
        <div className="custom-image-upload  mb-3 d-flex justify-content-center align-items-center position-relative">
          {fileType !== "image" && (
            <p onClick={() => window.open(pdfUrlUrl(item))} className="c-p">
              Document
            </p>
          )}
          {fileType == "image" && (
            <img
              src={imageUrl(item)}
              className="w-100 h-100"
              onClick={() => window.open(imageUrl(item))}
            />
          )}
          <IoMdClose
            size={15}
            className="position-absolute btn-color"
            onClick={() => {
              handleDeleteImage();
            }}
            style={{
              top: "5px",
              right: "5px",
              borderRadius: "3px",
              ...(fileType == "pdf" && { color: "black !important" }),
              ...(fileType == "image" && { color: "white !important" }),
            }}
          />
        </div>
      ) : (
        <div
          className="custom-image-upload  mb-3 d-flex justify-content-center align-items-center"
          onClick={() => getImage()}
        >
          <div className="text-center">
            {fileType == "image" && <AiOutlineCamera size={25} />}
            {fileType == "pdf" && <GrDocumentPdf size={25} />}
            <p className="upload-image-title mt-2">Upload {fileType}</p>
          </div>
        </div>
      )}
      <input
        type="file"
        className="d-none"
        ref={inputRef}
        onChange={(e) => {
          handleImageSubmit(e);
        }}
      />
    </>
  );
};

export default AddImageComponent;
