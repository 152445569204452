import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import EmailIcon from "@mui/icons-material/Email";
import { BaseURL, validateEmail } from "../Config/apiUrl";
import { toast } from "react-toastify";
import { Post } from "../Axios/AxiosFunctions";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #DA06E4",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

export default function ForgetPasswordModal({ open, setOpen }) {
  const handleClose = () => setOpen(false);
  const [email, setEmail] = React.useState("");
  const [isApiCall, setIsApiCall] = React.useState(false);

  const authHeader = {
    headers: {
      Accept: "application/json",
    },
  };

  const handleForgot = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      return toast.error("Pleas enter a valid email address");
    }

    const url = BaseURL("auth/forgotPassword");
    setIsApiCall(true);
    const response = await Post(url, { email }, authHeader);
    setIsApiCall(false);

    if (response !== undefined) {
      setEmail("");
      toast.success("Reset password link has been sent to your email address");
      setOpen(false);
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <form class="login100-form validate-form">
              <span class="login100-form-title"> Forgot your password? </span>

              <div
                class="wrap-input100 validate-input"
                data-validate="Username is reauired"
              >
                <span class="label-input100">Email</span>
                <input
                  class="input100"
                  type="text"
                  name="email"
                  placeholder="Type your email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
                <span class="focus-input100">
                  <EmailIcon />
                </span>
              </div>

              {/* btn */}
              <div class="container-login100-form-btn">
                <div class="wrap-login100-form-btn">
                  <div class="login100-form-bgbtn"></div>
                  <button
                    disabled={isApiCall}
                    class="login100-form-btn"
                    onClick={(e) => handleForgot(e)}
                  >
                    {isApiCall ? "Sending Email.." : "Send Email"}
                  </button>
                </div>
              </div>
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
