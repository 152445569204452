import React, { useState } from "react";
import ReactQuill from "react-quill";
import { useSelector, useDispatch } from "react-redux";
import { saveLoginUserData } from "../store/Actions/authAction";
import { Get, Post, Patch } from "../Axios/AxiosFunctions";
import { apiHeader, BaseURL } from "../Config/apiUrl";
import { toast } from "react-toastify";
import ScreenBoiler from "../components/ScreenBoiler";

function UpdatePassword() {
  const dispatch = useDispatch();

  const [passwordCurrent, setPasswordCurrent] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [isLoadingPassword, setIsLoadingPassword] = useState(false);

  const accessToken = useSelector((state) => state.authReducer.access_token);

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    const params = {
      passwordCurrent,
      password,
      passwordConfirm,
    };

    for (let key in params) {
      if (params[key] == "") {
        return toast.error("All password fields are required");
      }
    }

    if (password !== passwordConfirm) {
      return toast.error("New password should match with confirm password.");
    }

    const url = BaseURL("users/updateMyPassword");
    setIsLoadingPassword(true);
    const response = await Patch(url, params, apiHeader(accessToken));
    setIsLoadingPassword(false);

    if (response !== undefined) {
      toast.success("Password updated successfully");
      dispatch(saveLoginUserData(response?.data));
      setPasswordCurrent("");
      setPassword("");
      setPasswordConfirm("");
    }
  };

  return (
    <ScreenBoiler>
      <section className=" reported-ads">
        <div className="fieldSetHeaderContainer d-flex flex-column">
          <h1>UPDATE PASSWORD</h1>
        </div>

        <div className="fieldSetMainContainer">
          {/* Old */}
          <div className="mb-4">
            <label for="exampleInputEmail1">Old Password</label>
            <input
              type="password"
              className="form-control"
              id="exampleInputEmail1"
              value={passwordCurrent}
              onChange={(e) => setPasswordCurrent(e.target.value)}
            />
            {/* New */}
          </div>
          <div className="mb-4">
            <label for="exampleInputEmail1">New Password</label>
            <input
              type="password"
              className="form-control"
              id="exampleInputEmail1"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          {/* Re-New */}
          <div className="mb-5">
            <label for="exampleInputEmail1">Re-Enter Password</label>
            <input
              type="password"
              className="form-control"
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
            />
          </div>
          {/* btn */}
          <button
            disabled={isLoadingPassword}
            type="submit"
            className="custom-btn px-3 text-white"
            onClick={(e) => handlePasswordChange(e)}
          >
            {isLoadingPassword ? "PLease wait" : "Change Password"}
          </button>
        </div>
      </section>
    </ScreenBoiler>
  );
}

export default UpdatePassword;
