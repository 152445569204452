const BeforeLoadContentLoader = ({}) => {
  return (
    <div
      class="d-flex justify-content-center align-items-center"
      style={{
        position: "relative",
        visibility: "visible",
        top: "40vh",
        left: "auto",
        right: "auto",
        width: "100%",
      }}
    >
      <div class="spinner-grow" role="status" />
    </div>
  );
};

export default BeforeLoadContentLoader;
