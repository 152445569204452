import React, { useState } from "react";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import ModalSkeleton from "./ModalSkeleton";

const AddAndEditStateModal = ({
  open,
  setOpen,
  data,
  isLoading,
  handleSubmit,
}) => {
  // states
  const [name, setName] = useState(data?.state || "");
  const [headingH1, setHeadingH1] = useState(data?.headingH1 || "");
  const [headingH2, setHeadingH2] = useState(data?.headingH2 || "");
  const [metaTitle, setMetaTitle] = useState(data?.metaTitle || "");
  const [metaDescription, setMetaDescription] = useState(
    data?.metaDescription || ""
  );
  const [stateCode, setStateCode] = useState(data?.stateCode || "");

  //   btnClickHandler
  const btnClickHandler = (e) => {
    e.preventDefault();
    const params = {
      state: name,
      headingH1: headingH1,
      headingH2: headingH2,
      metaTitle: metaTitle,
      metaDescription: metaDescription,
      stateCode: stateCode,
    };

    // validate
    if (params.state === "") return toast.warn(`State Name is required`);
    if (params.stateCode === "" || params.stateCode === null)
      return toast.warn(`State Code is required`);

    handleSubmit(params);
  };

  return (
    <ModalSkeleton backdrop={true} open={open} setOpen={setOpen}>
      <form className="login100-form validate-form">
        <div className="custom_row">
          <span className="login100-form-title text-left text-color pb-4">
            {data ? "Edit" : "Add"} State
          </span>
          <div className="closeIcon_cus">
            <CloseIcon onClick={() => setOpen(false)} />
          </div>
        </div>

        {/* name */}
        <div>
          <label>State Name</label>
          <input
            className="add-evidence-modal-input full"
            type="text"
            placeholder="State Name Here"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>
        <div>
          <label>Heading H1</label>
          <input
            className="add-evidence-modal-input full"
            type="text"
            placeholder="Heading H1 Here"
            value={headingH1}
            onChange={(e) => {
              setHeadingH1(e.target.value);
            }}
          />
        </div>
        <div>
          <label>Heading H2</label>
          <input
            className="add-evidence-modal-input full"
            type="text"
            placeholder="Heading H2 Here"
            value={headingH2}
            onChange={(e) => {
              setHeadingH2(e.target.value);
            }}
          />
        </div>
        <div>
          <label>Meta Title</label>
          <input
            className="add-evidence-modal-input full"
            type="text"
            placeholder="Meta Title Here"
            value={metaTitle}
            onChange={(e) => {
              setMetaTitle(e.target.value);
            }}
          />
        </div>
        <div>
          <label>Meta Description</label>
          <input
            className="add-evidence-modal-input full"
            type="text"
            placeholder="Meta Description Here"
            value={metaDescription}
            onChange={(e) => {
              setMetaDescription(e.target.value);
            }}
          />
        </div>
        <div>
          <label>State Code</label>
          <input
            className="add-evidence-modal-input full"
            type="text"
            placeholder="State Code Here"
            value={stateCode}
            onChange={(e) => {
              setStateCode(e.target.value);
            }}
          />
        </div>

        {/* btn */}
        <div className="container-login100-form-btn mt-4">
          <div className="wrap-login100-form-btn">
            <div className="login100-form-bgbtn"></div>
            <button
              disabled={isLoading}
              className="login100-form-btn"
              onClick={btnClickHandler}
            >
              {isLoading ? "Please Wait..." : "Submit"}
            </button>
          </div>
        </div>
      </form>
    </ModalSkeleton>
  );
};

export default AddAndEditStateModal;
