import React from "react";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import TopicIcon from "@mui/icons-material/Topic";
import QuizIcon from "@mui/icons-material/Quiz";
import LaptopChromebookIcon from "@mui/icons-material/LaptopChromebook";
import moment from "moment";

import UIList from "./UIList";
import ExpandWrapper from "./ExpandWrapper";

const InnerUIList = ({ data }) => {
  return (
    <ExpandWrapper>
      <div className="sm-list">
        {/* Exam */}
        <h3>Exam</h3>
        {/* Quiz */}
        <UIList
          t1={`Exam Name: ${data?.exam?.examName}`}
          d1={`No. of attempts: ${data?.exam?.attempts}`}
          t2={`Active: ${data?.exam?.isActive ? "Yes" : "No"}`}
          d2={`Exam Duration: ${data?.exam?.duration} mins`}
          Icon={LaptopChromebookIcon}
          questions={data?.exam?.questions}
          expand
        />
        {/*  */}
        <h3 className="mt-3">Lesson</h3>
        {/* Lessons */}
        {data?.lessons?.map((lesson, i) => (
          <>
            <UIList
              key={i}
              t1={`Lesson Name: ${lesson?.lessonName}`}
              d1={`Last Updated: ${moment(lesson?.updatedAt).format("lll")}`}
              t2={`Active: ${lesson?.isActive ? "Yes" : "No"}`}
              Icon={CastForEducationIcon}
            />
            {/* Topics */}
            <div className="sm-list2">
              <h3 className="mt-3">Topic</h3>
              {lesson?.topics?.map((topic, i) => (
                <>
                  <UIList
                    key={i}
                    t1={`Topic Name: ${topic?.topicName}`}
                    d1={`Last Updated: ${moment(topic?.updatedAt).format(
                      "lll"
                    )}`}
                    t2={`Active: ${topic?.isActive ? "Yes" : "No"}`}
                    Icon={TopicIcon}
                    expandedHeading="Description"
                    topicDescription={topic?.description}
                    pdf={topic?.pdf}
                    video={topic?.video}
                    expand
                  />
                  {/* Quiz */}
                  <div className="sm-list3">
                    <h3 className="mt-3">Quiz</h3>
                    <UIList
                      t1={`Quiz Name: ${topic?.quiz?.quizName}`}
                      d1={`Last Updated: ${moment(
                        topic?.quiz?.updatedAt
                      ).format("lll")}`}
                      t2={`Active: ${topic?.quiz?.isActive ? "Yes" : "No"}`}
                      d2={`Quiz Duration: ${topic?.quiz?.duration} mins`}
                      Icon={QuizIcon}
                      questions={topic?.quiz?.questions}
                      expand
                    />
                  </div>
                </>
              ))}
            </div>
            {lesson?.topics?.length === 0 && (
              <div className="d-flex align-items-center justify-content-center">
                <p className="">No Topic Added!</p>
              </div>
            )}
          </>
        ))}

        {data?.lessons?.length === 0 && (
          <div className="d-flex align-items-center justify-content-center">
            <p className="">No Lesson Added!</p>
          </div>
        )}
      </div>
    </ExpandWrapper>
  );
};

export default InnerUIList;
