import React from "react";
import { Row, Col } from "react-bootstrap";
import ModalSkeleton from "./ModalSkeleton";

const StateViewDetailModal = ({ data, open, setOpen }) => {
  console.log(data, "data=========");
  return (
    <>
      <ModalSkeleton open={open} setOpen={setOpen}>
        <div>
          <h3 className="text-center mb-4">State Detail</h3>
          <Row>
            <Col md={6}>
              <h6>Name</h6>
              <p>{data?.state}</p>
            </Col>
            <Col md={6}>
              <h6>Heading H1</h6>
              <p>{data?.headingH1}</p>
            </Col>
            <Col md={6}>
              <h6>Heading H2</h6>
              <p>{data?.headingH2}</p>
            </Col>
            <Col md={6}>
              <h6>Meta Title</h6>
              <p>{data?.metaTitle}</p>
            </Col>
            <Col md={6}>
              <h6>Meta Description</h6>
              <p>{data?.metaDescription}</p>
            </Col>
            <Col md={6}>
              <h6>State Code</h6>
              <p>{data?.stateCode}</p>
            </Col>
          </Row>
        </div>
      </ModalSkeleton>
    </>
  );
};

export default StateViewDetailModal;
