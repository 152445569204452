import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Post, Delete, Patch, Get } from "../Axios/AxiosFunctions";
import Pagination from "../components/Pagination";
import TableRowsLoader from "../components/TableRowsLoader";
import moment from "moment";
import { BaseURL, apiHeader, recordsLimit } from "../Config/apiUrl";
import { Button } from "react-bootstrap";
import AreYouSureModal from "../components/AreYouSureModal";
// Mui Button
import MuiButton from "@mui/material/Button";
import { IoMdAddCircleOutline } from "react-icons/io";
import { toast } from "react-toastify";
import ScreenBoiler from "../components/ScreenBoiler";
import AddAndEditPackagesModalP from "../components/AddAndEditPackagesModalP";
import ShowMoreShowLessText from "../components/ShowMoreShowLessText";

function ComplainsScreen() {
  const [limit, setLimit] = useState(recordsLimit);
  const [pageNumber, setPageNumber] = useState(1);
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [apiData, setApiData] = useState([]);
  const [status, setStatus] = useState("pending");
  const [isGetting, setIsGetting] = useState(false);

  const [isAddAndEditModalOpen, setIsAddAndEditModalOpen] = useState(false);
  const [isAreYouSureModalOpen, setIsAreYouSureModalOpen] = useState(false);
  const [isAddEditApiCall, setIsAddEditApiCall] = useState(false);
  const [isDeleteApiCall, setIsDeleteApiCall] = useState(false);
  const [editingData, setEditingData] = useState(null);
  const [deletingId, setDeletingId] = useState(null);
  const [additionalData, setAdditionalData] = useState({});

  // get Item handler
  const getData = async (pageNo, newLimit, status) => {
    const url = BaseURL(
      `newsletters/admin/all?status=${status}&limit=${newLimit}&page=${pageNo}`
    );

    setIsGetting(true);
    const response = await Get(url, accessToken);
    setIsGetting(false);
    if (response !== undefined) {
      setApiData(response?.data?.data);
    }
  };

  // addAndupdateItemHandler
  const addAndupdateItemHandler = async () => {
    const url = BaseURL(`newsletters/admin/${additionalData?.id}`);
    const params = {
      status: additionalData?.status,
    };

    setIsAddEditApiCall(true);
    const response = await Patch(url, params, apiHeader(accessToken));
    setIsAddEditApiCall(false);

    if (response !== undefined) {
      // if we have updated
      const dataCopy = [...apiData];
      const index = dataCopy.findIndex(
        (item) => item._id === additionalData.id
      );
      dataCopy[index] = response.data;
      setApiData(dataCopy);

      toast.success(
        `Compalin ${
          additionalData?.status === "resolve" ? "Resolved" : "Rejected"
        } Successfully`
      );
      setEditingData(null);
      setIsAddAndEditModalOpen(false);
      setIsAreYouSureModalOpen(false);
    }
  };

  // deleteItemHandler
  const deleteItemHandler = async () => {
    const url = BaseURL(`newsletters/admin/${additionalData?.id}`);

    setIsDeleteApiCall(true);
    const response = await Delete(url, null, apiHeader(accessToken));
    setIsDeleteApiCall(false);

    if (response !== undefined) {
      const dataCopy = [...apiData];
      const index = dataCopy.findIndex(
        (item) => item._id === additionalData?.id
      );
      dataCopy.splice(index, 1);
      setApiData(dataCopy);
      setIsAreYouSureModalOpen(false);
      toast.success(`State deleted succeccfully!`);
    }
  };

  // fire on component mount
  useEffect(() => {
    getData(1, limit, status);
  }, []);

  // pagination handler

  const HandlepageChange = (value) => {
    const newPageNumber = pageNumber + value;
    setPageNumber(newPageNumber);
    getData(newPageNumber, limit);
  };
  const HandlepageStatus = (value) => {
    getData(1, limit, value);
  };

  return (
    <ScreenBoiler>
      <section className="reported-ads mt-5">
        <div className="fieldSetHeaderContainer d-flex flex-column">
          <h1>Contact Us</h1>
        </div>

        <div className="fieldSetMainContainer">
          <div className="place-add-btn-container mb-4">
            <div className="d-flex align-items-center">
              <p className="m-0 me-3 fw-bold">Records Per Page:</p>
              <select
                value={limit}
                onChange={(e) => {
                  setLimit(e.target.value);
                  setPageNumber(1);
                  HandlepageChange(1, e.target.value);
                }}
                className="px-3 py-2 custom-selection"
              >
                <option value={10} className="pt-2">
                  10
                </option>
                <option value={25}>25</option>
                <option value={50}>50</option>
              </select>
            </div>
            <div className="d-flex align-items-center">
              <p className="m-0 me-3 fw-bold">Status:</p>
              <select
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                  setPageNumber(1);
                  HandlepageStatus(e.target.value);
                }}
                className="px-3 py-2 custom-selection"
              >
                <option value={"pending"}>All</option>
                <option value={"seen"}>Seen</option>
                <option value={"rejected"}>Rejected</option>
              </select>
            </div>

            {/* <div className="d-flex align-items-center">
              <MuiButton
                onClick={() => {
                  setEditingData(null);
                  setIsAddAndEditModalOpen(true);
                }}
                variant="contained"
                color="primary"
                className="custom-btn rounded-lg radius-8 ms-4"
                startIcon={<IoMdAddCircleOutline />}
              >
                Add
              </MuiButton>
            </div> */}
          </div>

          {/* Table */}
          {isGetting ? (
            <TableRowsLoader />
          ) : (
            <div className="col-md-12 table_recent">
              <div className="table-responsive">
                <table
                  className="table"
                  id="dataTable"
                  width="100%"
                  cellspacing="0"
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Contact</th>
                      <th>Email</th>
                      <th>Message</th>

                      <th>Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    {apiData?.map((item, index) => {
                      console.log(item, "11111111111");
                      return (
                        <tr key={item?._id}>
                          <td>{++index}</td>
                          <td className="t-t-c">{item?.firstName || "-"}</td>
                          <td>{item?.lastName}</td>
                          <td>{item?.contact}</td>
                          <td className="t-t-c">{item?.email}</td>
                          <td>
                            <ShowMoreShowLessText text={item?.message} />
                          </td>

                          <td>
                            <ul className="d-flex">
                              {
                                <li
                                  className="page-item align-self-end me-2"
                                  onClick={() => {
                                    setAdditionalData({
                                      id: item?._id,
                                      status: "rejected",
                                    });
                                    setIsAreYouSureModalOpen(true);
                                  }}
                                >
                                  <Button className="update-btn">Delete</Button>
                                </li>
                              }

                              {item?.status == "pending" && (
                                <li
                                  className="page-item align-self-end me-2"
                                  onClick={() => {
                                    setAdditionalData({
                                      id: item?._id,
                                      status:
                                        item?.status == "pending" && "seen",
                                    });
                                    setIsAreYouSureModalOpen(true);
                                  }} 
                                >
                                  <Button className="update-btn">
                                    {item?.status == "pending" && "seen"}
                                  </Button>
                                </li>
                              )}
                            </ul>
                          </td>
                        </tr>
                      );
                    })}

                    {apiData?.length == 0 && (
                      <tr className="no-data-table-row">
                        <td colSpan={8}>
                          <div className="d-flex align-items-center justify-content-center no-data-table-content">
                            <p className="">No Data Found!</p>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          <Pagination
            maxLastLength={limit}
            array={apiData}
            HandlepageChange={HandlepageChange}
            pageNumber={pageNumber}
          />
        </div>

        {/* AreYouSureModal */}
        {isAreYouSureModalOpen && (
          <AreYouSureModal
            open={isAreYouSureModalOpen}
            setOpen={setIsAreYouSureModalOpen}
            label={"Are You Sure?"}
            message={
              additionalData?.status == "rejected"
                ? `Do You Reallay Want To Delete?`
                : `Do you really want to Seen this Contact?`
            }
            handleYes={
              additionalData?.status == "rejected"
                ? deleteItemHandler
                : addAndupdateItemHandler
            }
            isLoading={isAddEditApiCall}
          />
        )}

        {isAddAndEditModalOpen && (
          <AddAndEditPackagesModalP
            open={isAddAndEditModalOpen}
            setOpen={setIsAddAndEditModalOpen}
            handleSubmit={addAndupdateItemHandler}
            data={editingData}
            isLoading={isAddEditApiCall}
          />
        )}
      </section>
    </ScreenBoiler>
  );
}

export default ComplainsScreen;
