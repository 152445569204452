import React, { useEffect, useState } from "react";
import "../assets/Styles/Earnings.css";
import ScreenBoiler from "../components/ScreenBoiler";
import { BaseURL } from "../Config/apiUrl";
import { Get } from "../Axios/AxiosFunctions";
import { useSelector } from "react-redux";
import TableRowsLoader from "../components/TableRowsLoader";
import moment from "moment";
import { Button } from "react-bootstrap";
const Earnings = () => {
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [earning, setEarning] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isGetting, setIsGetting] = useState(false);
  const [status, setStatus] = useState("monthlyEarning");

  console.log(status, "statusstatusstatusstatus");
  // get Data
  const getData = async () => {
    const url = BaseURL("users/admin/statistics");
    setIsGetting(true);
    const response = await Get(url, accessToken);
    setIsGetting(false);
    if (response !== undefined) {
      setEarning(response?.data?.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <ScreenBoiler>
        <section className="reported-ads mt-5">
          <div className="fieldSetHeaderContainer d-flex flex-column">
            <h1>
              {status == "monthlyEarning"
                ? "Monthly Earning"
                : "Yearly Earning "}
            </h1>
          </div>

          <div className="fieldSetMainContainer">
            <div className="place-add-btn-container mb-4">
              <div className="d-flex align-items-center"></div>
              <div className="d-flex align-items-center">
                <p className="m-0 me-3 fw-bold">Earning Status:</p>
                <select
                  value={status}
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                  className="px-3 py-2 custom-selection"
                >
                  <option value={"monthlyEarning"} className="pt-2">
                    Monthly Earning
                  </option>
                  <option value={"yearlyEarning"}> Yearly Earning</option>
                </select>
              </div>
            </div>

            {/* Table */}
            {isGetting ? (
              <TableRowsLoader />
            ) : (
              <div className="col-md-12 table_recent">
                <div className="table-responsive">
                  <table
                    className="table"
                    id="dataTable"
                    width="100%"
                    cellspacing="0"
                  >
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Package Type</th>
                        <th>Price</th>
                        <th>Amount</th>
                        <th>Created At</th>
                        <th>Updated At</th>
                      </tr>
                    </thead>

                    <tbody>
                      {earning?.[status]?.map((item, index) => {
                        console.log(item, "item---------------");
                        return (
                          <tr key={item?._id}>
                            <td>{++index}</td>
                            <td className="t-t-c">
                              {item?.package?.packageType || "-"}
                            </td>
                            <td>{item?.package?.price || "-"}</td>
                            <td>{item?.amount || "-"}</td>
                            <td>
                              {moment(item?.package?.createdAt).format(
                                "ddd, DD MMM YYYY hh:mm A"
                              )}
                            </td>
                            <td>
                              {moment(item?.package?.updatedAt).format(
                                "ddd, DD MMM YYYY hh:mm A"
                              )}
                            </td>
                          </tr>
                        );
                      })}

                      {earning?.monthlyEarning?.length == 0 && (
                        <tr className="no-data-table-row">
                          <td colSpan={8}>
                            <div className="d-flex align-items-center justify-content-center no-data-table-content">
                              <p className="">No Data Found!</p>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            )}

            {/* <Pagination
            maxLastLength={limit}
            array={apiData}
            HandlepageChange={HandlepageChange}
            pageNumber={pageNumber}
          /> */}
          </div>
        </section>
      </ScreenBoiler>
    </div>
  );
};

export default Earnings;
