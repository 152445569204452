import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Post, Delete, Patch, Get } from "../Axios/AxiosFunctions";
import Pagination from "../components/Pagination";
import TableRowsLoader from "../components/TableRowsLoader";
import moment from "moment";
import { BaseURL, apiHeader, recordsLimit } from "../Config/apiUrl";
import { Button } from "react-bootstrap";
import AreYouSureModal from "../components/AreYouSureModal";
// Mui Button
import MuiButton from "@mui/material/Button";
import { IoMdAddCircleOutline } from "react-icons/io";
import { toast } from "react-toastify";
import ScreenBoiler from "../components/ScreenBoiler";
import AddAndEditPackagesModalP from "../components/AddAndEditPackagesModalP";
import ShowMoreShowLessText from "../components/ShowMoreShowLessText";

function ComplainsScreen() {
  const [limit, setLimit] = useState(recordsLimit);
  const [pageNumber, setPageNumber] = useState(1);
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [apiData, setApiData] = useState([]);
  const [status, setStatus] = useState("all");
  const [isGetting, setIsGetting] = useState(false);

  const [isAddAndEditModalOpen, setIsAddAndEditModalOpen] = useState(false);
  const [isAreYouSureModalOpen, setIsAreYouSureModalOpen] = useState(false);
  const [isAddEditApiCall, setIsAddEditApiCall] = useState(false);
  const [isDeleteApiCall, setIsDeleteApiCall] = useState(false);
  const [editingData, setEditingData] = useState(null);
  const [deletingId, setDeletingId] = useState(null);
  const [additionalData, setAdditionalData] = useState({});

  // get Item handler
  const getData = async (pageNo, newLimit, statuss) => {
    console.log(statuss, "statuss statuss statuss");
    const url = BaseURL(
      `complains/admin?page=${pageNo}&limit=${newLimit}&status=${statuss}`
    );

    setIsGetting(true);
    const response = await Get(url, accessToken);
    setIsGetting(false);
    if (response !== undefined) {
      setApiData(response?.data?.complain);
    }
  };

  // addAndupdateItemHandler
  const addAndupdateItemHandler = async () => {
    const url = BaseURL(`complains/admin/${additionalData?.id}`);
    const params = {
      ...(additionalData?.status === "resolve" && { status: "resolved" }),
      ...(additionalData?.status !== "resolve" && { status: "rejected" }),
    };

    setIsAddEditApiCall(true);
    const response = await Patch(url, params, apiHeader(accessToken));
    setIsAddEditApiCall(false);

    if (response !== undefined) {
      // if we have updated
      const dataCopy = [...apiData];
      const index = dataCopy.findIndex(
        (item) => item._id === additionalData.id
      );
      dataCopy[index] = response.data;
      setApiData(dataCopy);

      toast.success(
        `Compalin ${
          additionalData?.status === "resolve" ? "Resolved" : "Rejected"
        } Successfully`
      );
      setEditingData(null);
      setIsAddAndEditModalOpen(false);
      setIsAreYouSureModalOpen(false);
    }
  };
  const handleDownload = async (id) => {
    console.log(id, ":id id idid");
    const response = await Post(
      BaseURL(`twilio/fetch/media/recording`),
      { sid: id },
      apiHeader(accessToken)
    );
    if (response != undefined) {
      window?.open(response?.data?.data, "_blank");
    }
  };

  // deleteItemHandler
  const deleteItemHandler = async () => {
    const url = BaseURL(`state/${deletingId}`);

    setIsDeleteApiCall(true);
    const response = await Delete(url, null, apiHeader(accessToken));
    setIsDeleteApiCall(false);

    if (response !== undefined) {
      const dataCopy = [...apiData];
      const index = dataCopy.findIndex((item) => item._id === deletingId);
      dataCopy.splice(index, 1);
      setApiData(dataCopy);
      setIsAreYouSureModalOpen(false);
      toast.success(`State deleted succeccfully!`);
    }
  };

  // fire on component mount
  useEffect(() => {
    getData(1, limit, "all");
  }, []);

  // pagination handler

  const HandlepageChange = (value) => {
    const newPageNumber = pageNumber + value;
    setPageNumber(newPageNumber);
    getData(newPageNumber, limit);
  };
  const HandlepageStatus = (value) => {
    getData(1, limit, value);
  };

  return (
    <ScreenBoiler>
      <section className="reported-ads mt-5">
        <div className="fieldSetHeaderContainer d-flex flex-column">
          <h1>Refunds</h1>
        </div>

        <div className="fieldSetMainContainer">
          <div className="place-add-btn-container mb-4">
            <div className="d-flex align-items-center">
              <p className="m-0 me-3 fw-bold">Records Per Page:</p>
              <select
                value={limit}
                onChange={(e) => {
                  setLimit(e.target.value);
                  setPageNumber(1);
                  HandlepageChange(1, e.target.value);
                }}
                className="px-3 py-2 custom-selection"
              >
                <option value={10} className="pt-2">
                  10
                </option>
                <option value={25}>25</option>
                <option value={50}>50</option>
              </select>
            </div>
            <div className="d-flex align-items-center">
              <p className="m-0 me-3 fw-bold">Status:</p>
              <select
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                  setPageNumber(1);
                  // HandlepageStatus(e.target.value);
                  getData(1, limit, e.target.value);
                }}
                className="px-3 py-2 custom-selection"
              >
                <option value={"all"} className="pt-2">
                  All
                </option>
                <option value={"pending"}>Pending</option>
                <option value={"resolved"}>Resolved</option>
                <option value={"rejected"}>Rejected</option>
              </select>
            </div>

            {/* <div className="d-flex align-items-center">
              <MuiButton
                onClick={() => {
                  setEditingData(null);
                  setIsAddAndEditModalOpen(true);
                }}
                variant="contained"
                color="primary"
                className="custom-btn rounded-lg radius-8 ms-4"
                startIcon={<IoMdAddCircleOutline />}
              >
                Add
              </MuiButton>
            </div> */}
          </div>

          {/* Table */}
          {isGetting ? (
            <TableRowsLoader />
          ) : (
            <div className="col-md-12 table_recent">
              <div className="table-responsive">
                <table
                  className="table"
                  id="dataTable"
                  width="100%"
                  cellspacing="0"
                >
                  <thead>
                    <tr
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <th>#</th>
                      <th>Company Name</th>
                      <th>Company Email</th>
                      <th>Reason</th>
                      <th>User Name</th>
                      <th>User Email</th>
                      <th>Compalin Status</th>
                      <th>Complain Requested At</th>
                      <th>Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    {apiData?.map((item, index) => {
                      console.log(item, "oaaaaaa");
                      return (
                        <tr key={item?._id}>
                          <td>{++index}</td>
                          <td className="t-t-c">
                            {item?.company?.companyName || "-"}
                          </td>
                          <td>{item?.company?.email}</td>
                          <td>
                            <ShowMoreShowLessText text={item?.reason} />
                          </td>
                          <td className="t-t-c">
                            {item?.lead?.firstName
                              ? `${item?.lead?.firstName} ${item?.lead?.lastName}`
                              : "User"}
                          </td>
                          <td>{item?.lead?.email || "-"}</td>
                          <td className="t-t-c">{item?.status}</td>
                          <td>
                            {moment(item?.createdAt).format(
                              "ddd, DD MMM YYYY hh:mm A"
                            )}
                          </td>
                          <td>
                            <ul className="d-flex">
                              <li
                                className="page-item align-self-end me-2"
                                onClick={() => {
                                  handleDownload(item?.company?.twilio?.sid);
                                }}
                              >
                                <Button className="update-btn">
                                  Download Rec
                                </Button>
                              </li>
                              {item?.status === "pending" && (
                                <li
                                  className="page-item align-self-end me-2"
                                  onClick={() => {
                                    setAdditionalData({
                                      id: item?._id,
                                      status: "resolve",
                                    });
                                    setIsAreYouSureModalOpen(true);
                                  }}
                                >
                                  <Button className="update-btn">
                                    Approve
                                  </Button>
                                </li>
                              )}
                              {item?.status === "pending" && (
                                <li
                                  className="page-item align-self-end me-2"
                                  onClick={() => {
                                    setAdditionalData({
                                      id: item?._id,
                                      status: "reject",
                                    });
                                    setIsAreYouSureModalOpen(true);
                                  }}
                                >
                                  <Button className="update-btn">Reject</Button>
                                </li>
                              )}
                            </ul>
                          </td>
                        </tr>
                      );
                    })}

                    {apiData?.length == 0 && (
                      <tr className="no-data-table-row">
                        <td colSpan={8}>
                          <div className="d-flex align-items-center justify-content-center no-data-table-content">
                            <p className="">No Data Found!</p>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          <Pagination
            maxLastLength={limit}
            array={apiData}
            HandlepageChange={HandlepageChange}
            pageNumber={pageNumber}
          />
        </div>

        {/* AreYouSureModal */}
        {isAreYouSureModalOpen && (
          <AreYouSureModal
            open={isAreYouSureModalOpen}
            setOpen={setIsAreYouSureModalOpen}
            label={"Are You Sure?"}
            message={`Do you really want to ${additionalData?.status} this complain?`}
            handleYes={addAndupdateItemHandler}
            isLoading={isAddEditApiCall}
          />
        )}

        {isAddAndEditModalOpen && (
          <AddAndEditPackagesModalP
            open={isAddAndEditModalOpen}
            setOpen={setIsAddAndEditModalOpen}
            handleSubmit={addAndupdateItemHandler}
            data={editingData}
            isLoading={isAddEditApiCall}
          />
        )}
      </section>
    </ScreenBoiler>
  );
}

export default ComplainsScreen;
