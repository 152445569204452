import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MuiButton from "@mui/material/Button";
import { IoMdAddCircleOutline } from "react-icons/io";
import { Delete, Get, Patch, Post } from "../Axios/AxiosFunctions";
import Pagination from "../components/Pagination";
import TableRowsLoader from "../components/TableRowsLoader";
import moment from "moment";
import { useHistory } from "react-router";
import { BaseURL, apiHeader } from "../Config/apiUrl";
import { Button } from "react-bootstrap";
import AreYouSureModal from "../components/AreYouSureModal";
import AddCityModal from "../components/AddCityModal";
import ScreenBoiler from "../components/ScreenBoiler";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

// Mui Button

const Pages = () => {
  //   const history = useHistory();
  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [isGetting, setIsGetting] = useState(false);

  const [isAddingCity, setIsAddingCity] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingData, setEditingData] = useState();
  const [isDeleting, setIsDeleting] = useState(false);
  const [deletingId, setDeletingId] = useState("");
  const [pageStatus, setPageStatus] = useState("true");
  const [pages, setPages] = useState([]);
  const navigate = useNavigate();

  const getAllPages = async (status) => {
    setIsGetting(true);
    const url = BaseURL(`cms/page/all/new?all=${status}`);
    console.log("🚀 ~ file: Pages.js ~ line 39 ~ getAllPages ~ url", url);

    const responseData = await Get(url, accessToken);
    if (responseData !== undefined) {
      setPages(responseData?.data?.data);
    } else {
      console.log("error");
    }
    setIsGetting(false);
  };

  const addCity = async (data, setIsApiCall) => {
    setIsApiCall(true);
    const url = BaseURL(`city`);
    let params = {
      name: data,
    };

    const responseData = await Post(url, params, apiHeader(accessToken));
    if (responseData !== undefined) {
      setIsApiCall(false);
      toast.success("Added successfully");
      setIsAddingCity(false);
      getAllPages(1, limit);
      return;
    }
    setIsApiCall(false);
  };

  const updateCity = async (data, cityId, setIsApiCall) => {
    setIsApiCall(true);
    const url = BaseURL(`city/${cityId}`);
    let params = {
      name: data,
    };

    const responseData = await Patch(url, params, apiHeader(accessToken));
    if (responseData !== undefined) {
      toast.success(`Updated successfully`);
      setIsEditing(false);
      getAllPages(1, limit);
      return;
    }
    getAllPages(1, limit);
  };

  const deleteCity = async () => {
    // const url = BaseURL(`city/${deletingId}`);
    // const responseData = await Delete(url, null, apiHeader(accessToken));
    // if (responseData !== undefined) {
    //   toast.success(`Deleted successfully`);
    //   getAllPages(1, limit);
    //   setIsDeleting(false);
    //   return;
    // }
  };

  useEffect(() => {
    getAllPages(pageStatus);
  }, []);

  return (
    <ScreenBoiler>
      <section className="reported-ads">
        <div className="fieldSetHeaderContainer d-flex flex-column">
          <h1>PAGES</h1>
        </div>

        <div className="fieldSetMainContainer mt-5">
          {isGetting ? (
            <TableRowsLoader />
          ) : (
            <div className="col-md-12 table_recent">
              <div className="table-responsive">
                <table
                  className="table"
                  id="dataTable"
                  width="100%"
                  cellSpacing="0"
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Page Name</th>
                      <th>Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    {pages.length < 1 && (
                      <tr className="no-data-table-row">
                        {" "}
                        <td colSpan={9}>
                          {" "}
                          <div className="d-flex align-items-center justify-content-center no-data-table-content">
                            <p className="">No Data Found!</p>
                          </div>
                        </td>
                      </tr>
                    )}

                    {pages?.map((item, index) => {
                      return (
                        <>
                          <tr key={item?._id}>
                            <td>{index + 1}</td>
                            <td>{item?.pageName}</td>
                            <td>
                              <ul className="d-flex">
                                <li
                                  className="page-item align-self-end"
                                  onClick={() => {
                                    setEditingData(item);
                                    //   setIsEditing(true);
                                    // navigate("homecms", {
                                    //   pathname: `/${item?.pageName}cms`,
                                    //   state: { item },
                                    // });
                                    navigate(`/${item?.pageName}`, {
                                      state: { item },
                                    });
                                  }}
                                >
                                  <Button className="update-btn me-2">
                                    Edit
                                  </Button>
                                </li>
                                {/* <li
                                className="page-item align-self-end ml-4"
                                onClick={() => {
                                  setDeletingId(item?._id);
                                  setIsDeleting(true);
                                }}
                              >
                                <Button className="delete-btn">Seo</Button>
                              </li> */}
                              </ul>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>

        <AreYouSureModal
          open={isDeleting}
          setOpen={setIsDeleting}
          label={"Are You Sure?"}
          message={`Do you really want to delete this specialization?`}
          handleYes={() => deleteCity()}
        />

        {isAddingCity && (
          <AddCityModal
            open={isAddingCity}
            setOpen={setIsAddingCity}
            onUpdate={addCity}
            type={"Add"}
          />
        )}
        {/* {isEditing && (
        <AddCityModal
          open={isEditing}
          setOpen={setIsEditing}
          onUpdate={updateCity}
          updating={"Edit"}
          prevData={editingData}
        />
      )} */}
      </section>
    </ScreenBoiler>
  );
};

export default Pages;
