import React, { useState, useEffect } from "react";
import ScreenBoiler from "../components/ScreenBoiler";
import { underProducation } from "../constant/imagePath";
import { Row, Col } from "react-bootstrap";
import StaticsCard from "../components/StaticsCard";
import TableRowsLoader from "../components/TableRowsLoader";
import { BaseURL } from "../Config/apiUrl";
import { Get } from "../Axios/AxiosFunctions";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const Dashbaord = (props) => {
  const [isGetting, setIsGetting] = useState(false);
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [stats, setStats] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const data = [
    {
      title: "Claimed Companies",
      totalno: 2000,
      type: "icon1",
    },
    {
      title: "Claimed Companies",
      totalno: 2000,
      type: "icon2",
    },
    {
      title: "Claimed Companies",
      totalno: 2000,
      type: "icon3",
    },
    {
      title: "Claimed Companies",
      totalno: 2000,
      type: "icon4",
    },
    {
      title: "Claimed Companies",
      totalno: 2000,
      type: "icon5",
    },
    {
      title: "Claimed Companies",
      totalno: 2000,
      type: "icon6",
    },
  ];

  // get Data
  const getData = async () => {
    const url = BaseURL("users/admin/statistics");
    const response = await Get(url, accessToken);
    if (response !== undefined) {
      setStats(response?.data?.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <ScreenBoiler>
      <div className="dashboard-container">
        <div className="dashboard-container-overlay"></div>
        <div className="dashboard-inner-container">
          <h4>Home</h4>
          <Row>
            <Col lg={9}>
              <Row>
                <div className="dashboard-card-div">
                  <Row className="dashboard-card-div-row">
                    <Col
                      style={{ cursor: "pointer" }}
                      lg={4}
                      onClick={() => navigate("/company", { state: true })}
                    >
                      <StaticsCard
                        title={"Claimed Companies"}
                        noOfListing={stats?.claimedCompaniesCount || "****"}
                        type={"icon1"}
                      />
                    </Col>

                    <Col
                      style={{ cursor: "pointer" }}
                      lg={4}
                      onClick={() =>
                        navigate("/company", { state: "unclaimed" })
                      }
                    >
                      <StaticsCard
                        title={"Unclaimed Companies"}
                        noOfListing={stats?.unclaimedCompaniesCount || "****"}
                        type={"icon1"}
                      />
                    </Col>

                    <Col lg={4}>
                      <StaticsCard
                        title={"Total Leads"}
                        noOfListing={stats?.leadCount || "****"}
                        type={"icon1"}
                      />
                    </Col>

                    <Col lg={4}>
                      <StaticsCard
                        title={"Total Earning"}
                        noOfListing={stats?.totalEarning || "****"}
                        type={"icon1"}
                      />
                    </Col>

                    <Col lg={4}>
                      <StaticsCard
                        title={"Total services"}
                        noOfListing={stats?.servicesCount || "****"}
                        type={"icon1"}
                      />
                    </Col>

                    <Col lg={4}>
                      <StaticsCard
                        title={"Refunds"}
                        noOfListing={stats?.refundedLeadsCount || "****"}
                        type={"icon1"}
                      />
                    </Col>
                  </Row>
                </div>
                <div className="dashboard-table-div">
                  <Row>
                    <Col lg={12}>
                      {/* Table */}
                      {isGetting ? (
                        <TableRowsLoader />
                      ) : (
                        <>
                          <h4>New Lead</h4>
                          <div className="col-md-12 table_recent">
                            <div className="table-responsive">
                              <table
                                className="table"
                                id="dataTable"
                                width="100%"
                                cellspacing="0"
                              >
                                <thead>
                                  <tr>
                                    {/* <th>S.No</th> */}
                                    <th>Company Name</th>
                                    <th>Services</th>
                                    <th>Total Token</th>
                                    <th>Wallet Balance</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {stats?.recentLeads?.length > 0 &&
                                    stats?.recentLeads?.map((item, index) => {
                                      return (
                                        <tr key={item?._id}>
                                          {/* <td>{++index}</td> */}
                                          <td className="t-t-c">
                                            {item?.company?.companyName || "-"}
                                          </td>
                                          <td>
                                            {item?.services?.map(
                                              (e) => `${e?.service?.name} - `
                                            )}
                                          </td>
                                          <td>{item?.totalTokens}</td>
                                          <td className="t-t-c">
                                            {item?.company?.wallet?.balance}
                                          </td>
                                        </tr>
                                      );
                                    })}

                                  {(stats?.recentLeads?.length == 0 ||
                                    isLoading) && (
                                    <tr className="no-data-table-row">
                                      <td colSpan={8}>
                                        <div className="d-flex align-items-center justify-content-center no-data-table-content">
                                          <p className="">No Data Found!</p>
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </>
                      )}
                    </Col>
                  </Row>
                </div>
              </Row>
            </Col>
            <Col lg={3}>
              <Row className="insect-div-container-row">
                <Col lg={12}>
                  <div className="insect-div-container">
                    <h5>Last Sold Leads</h5>
                    {stats?.recentLeads?.length > 0 &&
                      stats?.recentLeads?.slice(0, 5)?.map((item, i) => {
                        return (
                          <div className="insect-div-inner-container" key={i}>
                            <p className="t-t-c">{item.firstName}</p>
                            <p
                              style={{
                                fontWeight: "400",
                              }}
                            >
                              ${parseFloat(item?.totalTokens).toFixed(2)}
                            </p>
                          </div>
                        );
                      })}
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="topCompany-div-container">
                    <h5>Top 3 Companies</h5>
                    {stats?.topCompanies?.length > 0 &&
                      stats?.topCompanies?.map((item, i) => {
                        return (
                          <div className="insect-div-inner-container" key={i}>
                            <p
                              className="t-t-c"
                              title={item?.company?.companyName}
                            >
                              {item?.company?.companyName || "-"}
                            </p>
                            <p
                              style={{
                                fontWeight: "400",
                              }}
                            >
                              {item?.count} leads
                            </p>
                          </div>
                        );
                      })}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </ScreenBoiler>
  );
};

export default Dashbaord;

const data2 = [
  {
    title: "ant",
    no: 20,
  },
  {
    title: "rat",
    no: 30,
  },
  {
    title: "cat",
    no: 50,
  },
];
