import React, { useState } from "react";
import { toast } from "react-toastify";
import Select from "react-select";

import { formRegEx, formRegExReplacer } from "../Config/apiUrl";
import ModalSkeleton from "./ModalSkeleton";
import { Skeleton } from "@mui/material";

const AddAndEditExamModal = ({
  open,
  setOpen,
  data,
  isLoading,
  handleSubmit,
  questionArray,
  lessonsArray,
  getQuestionData,
}) => {
  // states
  const [examName, setExamName] = useState(data?.examName || "");
  const [duration, setDuration] = useState(data?.duration || "");
  const [attempts, setAttempts] = useState(data?.attempts || "");
  const [examQuestions, setExamQuestions] = useState(data?.questions || "");

  const [isLoadingQuestions, setIsLoadingQuestions] = useState(false);

  //   btnClickHandler
  const btnClickHandler = (e) => {
    e.preventDefault();
    const params = {
      examName,
      duration,
      attempts,
    };

    // validate
    for (let key in params)
      if (params[key] === "")
        return toast.warn(
          `${key.replace(formRegEx, formRegExReplacer)} is required`
        );

    if (examQuestions.length === 0) return toast.warn("Please add questions");
    params.questions = examQuestions.map((e) => e._id);

    handleSubmit(params);
  };

  return (
    <ModalSkeleton open={open} setOpen={setOpen}>
      <form className="login100-form validate-form">
        <span className="login100-form-title text-left text-color pb-4">
          {data ? "Edit" : "Add"} Exam
        </span>

        {/* Exam */}
        <div>
          <label>Exam Name</label>
          <input
            className="add-evidence-modal-input full"
            type="text"
            placeholder="Exam Name Here"
            value={examName}
            onChange={(e) => {
              setExamName(e.target.value);
            }}
          />
        </div>

        {/* Lessons */}
        <div className="mt-3">
          <label>Select Course</label>
          <Select
            defaultValue={lessonsArray[0]}
            options={lessonsArray}
            className="basic-multi-select"
            classNamePrefix="select"
            getOptionLabel={(option) => option.courseName}
            getOptionValue={(option) => option._id}
            onChange={async (e) => {
              setIsLoadingQuestions(true);
              await getQuestionData(e, false);
              setIsLoadingQuestions(false);
            }}
          />
        </div>

        {isLoadingQuestions && (
          <div className="mt-3">
            <Skeleton variant="rectangular" width={"100%"} height={40} />
          </div>
        )}


        {/* Quiz Questions */}
        {!isLoadingQuestions && (
          <div className="mt-3">
            <label>Select Questions</label>
            <Select
              defaultValue={examQuestions}
              isMulti
              options={questionArray}
              className="basic-multi-select"
              classNamePrefix="select"
              getOptionLabel={(option) => option.question}
              getOptionValue={(option) => option._id}
              onChange={(e) => {
                setExamQuestions(e);
              }}
            />
          </div>
        )}

        {/* Exam Duration */}
        <div className="mt-3">
          <label>Exam Duration (in minutes)</label>
          <input
            className="add-evidence-modal-input full"
            type="number"
            placeholder="Exam Duration Here"
            value={duration}
            onChange={(e) => {
              setDuration(e.target.value);
            }}
          />
        </div>

        {/* Number of Attempts */}
        <div className="mt-3">
          <label>Number of Attempts Name</label>
          <input
            className="add-evidence-modal-input full"
            type="number"
            placeholder="Number of Attempts Here"
            value={attempts}
            onChange={(e) => {
              setAttempts(e.target.value);
            }}
          />
        </div>

        {/* btn */}
        <div className="container-login100-form-btn mt-4">
          <div className="wrap-login100-form-btn">
            <div className="login100-form-bgbtn"></div>
            <button
              disabled={isLoading}
              className="login100-form-btn"
              onClick={btnClickHandler}
            >
              {isLoading ? "Please Wait..." : "Submit"}
            </button>
          </div>
        </div>
      </form>
    </ModalSkeleton>
  );
};

export default AddAndEditExamModal;
