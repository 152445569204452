import React, { useState } from "react";

import ModalSkeleton from "./ModalSkeleton";

const QueriesModal = ({
  open,
  setOpen,
  data,
}) => {



  return (
    <ModalSkeleton open={open} setOpen={setOpen}>
     <h1 className="text-center">Message</h1>
     <hr/>
     <p>{data}</p>
    </ModalSkeleton>
  );
};

export default QueriesModal;
