import React, { useEffect, useRef, useState } from "react";
import Quill from "react-quill";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import MuiButton from "@mui/material/Button";
import { Delete, Get, Patch, Post } from "../Axios/AxiosFunctions";
import AddImageComponent from "../components/AddImageComponent";
import { BaseURL, apiHeader, urlToObject, imageUrl } from "../Config/apiUrl";
import { toast } from "react-toastify";
import BeforeLoadContentLoader from "../components/BeforeLoadContentLoader";
import ScreenBoiler from "../components/ScreenBoiler";
import { appLogo } from "../constant/imagePath";
import { json } from "../assets/Json";
import { AiFillDelete } from "react-icons/ai";
import { Col, Row } from "react-bootstrap";

const SocialLinkCms = () => {
  const pageData = useLocation()?.state?.item;
  // console.log("🚀 ~ file: HomeCms.js ~ line 16 ~ HomeCms ~ pageData", pageData)
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const navigate = useNavigate();
  const [isApiCall, setIsApiCall] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pageName] = useState(pageData?.pageName);
  const [pageId] = useState(pageData?._id);
  // Home Section States Start //

  const [address, setAddress] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const ref = useRef();
  const [socialLinks, setSocialLinks] = useState([""]);
  console.log(socialLinks, "111111111");

  // Home Section States End //

  const getData = async () => {
    const url = BaseURL(`cms/page/all/new?pages=${pageData?.pageName}`);
    setIsApiCall(true);
    const response = await Get(url, accessToken);
    console.log(
      "🚀 ~ file: HomeCms.js ~ line 59 ~ getData ~ response",
      response
    );
    setIsApiCall(false);
    if (response !== undefined) {
      setAddress(response?.data?.data?.address);
      setContact(response?.data?.data?.contact);
      setEmail(response?.data?.data?.email);
      setSocialLinks(response?.data?.data.socialLinks);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    let timeOut = setTimeout(() => {
      setIsLoading(false);
    }, 2800);
    return () => clearTimeout(timeOut);
  }, []);

  //   handleSubmit
  const handleSubmit = async () => {
    const params = {
      _id: pageId,
      pageName,

      address,
      contact,
      email,
      //   socialLinks: socialLinks
    };
    const formData = new FormData();
    for (let key in params) {
      params[key] && formData.append(key, params[key]);
    }
    socialLinks.forEach((item) => {
      formData.append("socialLinks", item);
    });

    const url = BaseURL("cms/page/update");
    setIsApiCall(true);
    const response = await Patch(url, formData, apiHeader(accessToken, true));
    setIsApiCall(false);

    if (response !== undefined) {
      toast.success("Page data updated successfully");
      getData();
    }
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const handleAddMore = () => {
    setSocialLinks((prev) => [...prev, [""]]);
  };
  return (
    <ScreenBoiler>
      <>
        <section 
        
        style={{ visibility: isApiCall && "hidden" }}
        
        className="home-section">
          <div style={{ textAlign: "end" }} className="homepage_heading">
            <MuiButton
              onClick={() => navigate(-1)}
              variant="contained"
              color="primary"
              className="mt-3 custom-btn rounded-lg radius-8 mb-4"
            >
              Go Back
            </MuiButton>
          </div>
          <div className="homepage_heading">
            <h1>{"Social Link"} PAGE DETAILS</h1>
          </div>

          {isApiCall && <BeforeLoadContentLoader />}

          <div className="homeSection2">
            {/* 00 */}
            <p className="mb-0 mt-3">Address</p>
            <input
              className="admin_Input_cms"
              type="text"
              name="section1 title"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
          <div className="homeSection2">
            {/* 00 */}
            <p className="mb-0 mt-3">Email</p>
            <input
              className="admin_Input_cms"
              type="text"
              name="section1 title"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="homeSection2">
            {/* 00 */}
            <p className="mb-0 mt-3">Contact</p>
            <input
              className="admin_Input_cms"
              type="text"
              name="section1 title"
              value={contact}
              onChange={(e) => setContact(e.target.value)}
            />
          </div>
          {socialLinks?.map((item, index) => {
            return (
              <>
                {/* <div>
                  <input
                    //   onChange={(e) => set}
                    ref={ref}
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) =>
                      setSocialLinks(
                        (prev) => [...prev],
                        (socialLinks[index]["image"] = e.target.files[0])
                      )
                    }
                  />
                  <div
                    className="social_img_div"
                    onClick={() => ref.current.click()}
                  >
                    <img
                      src={
                        !!item?.image?.name
                          ? item?.image
                          : imageUrl(item?.image)
                      }
                    />
                  </div>
                </div> */}
                <Row>
                  <Col md={10}>
                    <div className="homeSection2">
                      {/* 00 */}
                      <p className="mb-0 mt-3">Social Link</p>
                      <input
                        className="admin_Input_cms"
                        type="text"
                        name="section1 title"
                        value={item}
                        onChange={(e) =>
                          setSocialLinks(
                            (prev) => [...prev],
                            (socialLinks[index] = e.target.value)
                          )
                        }
                      />
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="delBox">
                      <AiFillDelete
                        onClick={() =>
                          setSocialLinks((prev) =>
                            prev.filter((item, i) => i != index)
                          )
                        }
                      />
                    </div>
                  </Col>
                </Row>
              </>
            );
          })}

          <MuiButton
            onClick={() => handleAddMore()}
            variant="contained"
            color="primary"
            className="mt-3 custom-btn rounded-lg radius-8 mb-4"
          >
            Add More
          </MuiButton>

          <div className="home-cms-btn mt-3 text-center mb-4">
            <MuiButton
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              className="mt-3 custom-btn rounded-lg radius-8 ms-4"
              disabled={isApiCall}
            >
              {isApiCall ? "Updating..." : "Update"}
            </MuiButton>
          </div>
        </section>
      </>
    </ScreenBoiler>
  );
};

export default SocialLinkCms;
