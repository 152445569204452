import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Quill from "react-quill";
import "../assets/Styles/Templates.css";
import ModalSkeleton from "../components/ModalSkeleton";

const TemplateEditModal = ({
  open,
  setOpen,
  handleEdit,
  selectedData,
  isApiCall,
}) => {
  const [message, setMessage] = useState(selectedData?.message || "");
  const [subject, setSubject] = useState(selectedData?.subject || "");
  console.log(selectedData?.message, "selectedData?.message selectedData?.message ");
  return (
    <>
      <ModalSkeleton open={open} setOpen={setOpen}>
        <div className="temModalMain">
          <h2>Edit</h2>
          <div className="inner">
            <p>Subject</p>
            <span className="innerSpan">
            <input onChange={(e) => setSubject(e.target.value)} value={subject} type={"text"}/>
            </span>
          </div>
          <div className="inner">
            <p>Message</p>
            <Quill theme="snow" value={message} onChange={setMessage} />
          </div>

          <div className="innerBtn">
            <Button onClick={() => handleEdit(message , subject)}>
              {isApiCall ? "Editing..." : "Edit"}
            </Button>
          </div>
        </div>
      </ModalSkeleton>
    </>
  );
};

export default TemplateEditModal;
