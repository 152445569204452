import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import SideNavbar from "./SideNavbar";

const ScreenBoiler = ({ children }) => {
  const isAuthenticated = useSelector((state) => state.authReducer.isLogin);
  const drawerOpen = useSelector((state) => state.authReducer.isOpen);

  return (
    <Container fluid>
      <Row>
        {isAuthenticated && (
          <Col md={drawerOpen ? 2 : 1}>
            <SideNavbar />
          </Col>
        )}

        <Col md={`${isAuthenticated ? (drawerOpen ? 10 : 11) : 12}`}>
          {children}
        </Col>
      </Row>
    </Container>
  );
};

export default ScreenBoiler;
