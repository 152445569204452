import React from "react";
import { Container } from "react-bootstrap";
import { GoListUnordered } from "react-icons/go";
const StaticsCard = ({ title, noOfListing, type }) => {
  // const listingIcon1 = GoListUnordered;
  // const listingIcon2 = GoListUnordered;
  // const listingIcon3 = GoListUnordered;
  // const listingIcon4 = GoListUnordered;

  return (
    <>
      <div className="staticsCard-Container">
        <div className="staticsCard-inner-Container">
          <div className="staticsCard-text-Container">
            <p
              style={{
                fontSize: "14px",
                fontWeight: "700",
              }}
            >
              {title}
            </p>
            <p>{noOfListing}</p>
          </div>
          <div className="staticsCard-icon-Container">
            {/* {type == "listing1" ? <listingIcon1 /> : type == "listing2" ? <listingIcon2 /> : type == "listing3" ? <listingIcon3 /> : type == "listing4" ? <listingIcon4 /> } */}
            <GoListUnordered size={25} />
          </div>
        </div>
      </div>
    </>
  );
};

export default StaticsCard;
