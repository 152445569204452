import "./App.css";
import "./assets/Styles/style.css";
import "./assets/Styles/sideBar.css";
import "./assets/Styles/loginScreen.css";
import "./assets/Styles/AddCategory.css";
import "./assets/Styles/AddAndEditCategoryModal.css";
import "./assets/Styles/Pagination.css";
import "./assets/Styles/mainDashboard.css";
import "./assets/Styles/Users.css";
import "./assets/Styles/AddEvidenceModal.css";
import "./assets/Styles/imageComp.css";
import "./assets/Styles/StaticsCard.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-quill/dist/quill.snow.css";
import "./assets/Styles/dashboard.css";

import {
  Route,
  BrowserRouter as Router,
  Routes,
  useNavigate,
} from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Login from "./Screens/Login";
import ProtectedRoute from "./components/ProtectedRouter";
import Dashbaord from "./Screens/Dashbaord";
import PackgesScreenP from "./Screens/PackgesScreenP";
import UpdatePassword from "./Screens/UpdatePassword";
import Users from "./Screens/Users";
import CheckScreen from "./Screens/CheckScreen";
import PackagesScreen from "./Screens/PackagesScreen";
import CoursesScreen from "./Screens/CoursesScreen";
import ContactUs from "./Screens/ContactUs";
import SecurityQuestions from "./Screens/SecurityQuestions";
import CityScreen from "./Screens/CityScreen";
import QuestionsScreen from "./Screens/QuestionsScreen";
import TopicScreen from "./Screens/TopicScreen";
import ExamScreen from "./Screens/ExamScreen";
import DetailScreen from "./Screens/DetailScreen";
import ReAttemptionRequests from "./Screens/ReAttemptionRequests";
import ContactMessages from "./Screens/ContactMessages";
import ServicesScreen from "./Screens/ServicesScreen";
import Faq from "./Screens/Faq";
import Reports from "./Screens/Reports";
import CMSScreen from "./Screens/CMS";
import CMSDetail from "./Screens/CMSDetail";
import AfterExamQuestions from "./Screens/AfterExamQuestions";
import ComplainsScreen from "./Screens/ComplainsScreen";
import StateScreen from "./Screens/StateScreen";
import Cms from "./Screens/Pages";
import HomeCms from "./Screens/HomeCms";
import ListingCms from "./Screens/ListingCms";
import ListingDetailsCms from "./Screens/ListingDetailsCms";
import CertificateScreen from "./Screens/CertificateScreen";
import CreateBussines from "./Screens/CreateBussines";
import Queries from "./Screens/Queries";
import Earnings from "./Screens/Earnings";
import Earning from "./Screens/Earning";
import Disclaimer from "./Screens/Disclaimer";
import PrivacyPolicy from "./Screens/PrivacyPolicy";
import SocialLinkCms from "./Screens/SocialLinkCms";
import { useSelector } from "react-redux";
import Templates from "./Screens/Templates";

function App() {
  return (
    <>
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          {/* Dashboard */}
          <Route
            path="/"
            element={<ProtectedRoute component={<Dashbaord />} />}
          />
          {/* Companies */}
          <Route
            path="/company"
            element={<ProtectedRoute component={<Users />} />}
          />
          {/* ServicesScreen */}
          <Route
            path="/services"
            element={<ProtectedRoute component={<ServicesScreen />} />}
          />
          {/* CertificateScreen */}
          <Route
            path="/Certificates"
            element={<ProtectedRoute component={<CertificateScreen />} />}
          />
          {/* package oest radsr */}
          <Route
            path="/package"
            element={<ProtectedRoute component={<PackgesScreenP />} />}
          />
          {/* createBussines */}
          <Route
            path="/createBussines"
            element={<ProtectedRoute component={<CreateBussines />} />}
          />
          {/* Earnings */}
          <Route
            path="/earnings"
            element={<ProtectedRoute component={<Earnings />} />}
          />
          {/* complains oest radsr */}
          <Route
            path="/complains"
            element={<ProtectedRoute component={<ComplainsScreen />} />}
          />

          {/* Courses */}
          <Route
            path="/courses"
            element={<ProtectedRoute component={<CoursesScreen />} />}
          />
          {/* Contact Us */}
          <Route
            path="/contactUs"
            element={<ProtectedRoute component={<ContactUs />} />}
          />
          {/* city */}
          {/* <Route
            path="/city"
            element={<ProtectedRoute component={<CityScreen />} />}
          /> */}
          {/* State */}
          <Route
            path="/state"
            element={<ProtectedRoute component={<StateScreen />} />}
          />
          {/* SocialLinksCms */}
          <Route
            path="/socialLink"
            element={<ProtectedRoute component={<SocialLinkCms />} />}
          />
          <Route
            path="/queries"
            element={<ProtectedRoute component={<Queries />} />}
          />
          <Route
            path="/disclaimer"
            element={<ProtectedRoute component={<Disclaimer />} />}
          />
          <Route
            path="/privacyPolicy"
            element={<ProtectedRoute component={<PrivacyPolicy />} />}
          />
          {/* Question */}
          <Route
            path="/question"
            element={<ProtectedRoute component={<QuestionsScreen />} />}
          />
          {/* Topics */}
          <Route
            path="/topic/:id"
            element={<ProtectedRoute component={<TopicScreen />} />}
          />
          {/* Detail */}
          <Route
            path="/detail/:id"
            element={<ProtectedRoute component={<DetailScreen />} />}
          />
          {/* Exam */}
          <Route
            path="/exam"
            element={<ProtectedRoute component={<ExamScreen />} />}
          />
          {/* Earning */}
          <Route
            path="/earning"
            element={<ProtectedRoute component={<Earning />} />}
          />
          {/* security-questions */}
          <Route
            path="/security-questions"
            element={<ProtectedRoute component={<SecurityQuestions />} />}
          />
          {/* after-exam-questions */}
          <Route
            path="/after-exam-questions"
            element={<ProtectedRoute component={<AfterExamQuestions />} />}
          />

          {/* Users */}
          <Route
            path="/requests"
            element={<ProtectedRoute component={<ReAttemptionRequests />} />}
          />
          {/* Users */}
          <Route
            path="/contact"
            element={<ProtectedRoute component={<ContactMessages />} />}
          />

          {/* testimonial */}
          <Route path="/faq" element={<ProtectedRoute component={<Faq />} />} />

          {/* reports */}
          <Route
            path="/reports"
            element={<ProtectedRoute component={<Reports />} />}
          />
          {/* testimonial */}
          {/* <Route
            path="/cms"
            element={<ProtectedRoute component={<CMSScreen />} />}
          /> */}

          {/* content */}
          <Route
            path="/content"
            element={<ProtectedRoute component={<CMSDetail />} />}
          />
          <Route path="/cms" element={<ProtectedRoute component={<Cms />} />} />
          <Route
            path="/home"
            element={<ProtectedRoute component={<HomeCms />} />}
          />
          <Route
            path="/listing"
            element={<ProtectedRoute component={<ListingCms />} />}
          />
          <Route
            path="/listingDetails"
            element={<ProtectedRoute component={<ListingDetailsCms />} />}
          />
          <Route
            path="/templates"
            element={<ProtectedRoute component={<Templates />} />}
          />

          {/* <ProtectedRoute path="/admins" exact component={<AdminLists />} />
        <ProtectedRoute
          path="/updatepassword"
          exact
          component={<UpdatePassword />}
        />
        <ProtectedRoute path="/CheckScreen" exact component={<CheckScreen />} /> */}
        </Routes>
      </Router>
    </>
  );
}

export default App;
