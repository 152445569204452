import React from "react";
import CloseIcon from "@mui/icons-material/Close";

import ModalSkeleton from "./ModalSkeleton";
import PopOverUser from "./PopOverUser";
import { IconButton } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import ShowMoreShowLessText from "./ShowMoreShowLessText";
import moment from "moment";

const UserDetailModal = ({ open, setOpen, data: allData }) => {
  const { data, user, leads, statistics } = allData;

  return (
    <ModalSkeleton open={open} setOpen={setOpen} type="big">
      <form className="login100-form validate-form">
        <span className="login100-form-title text-left text-color pb-4 t-t-c">
          {user?.companyName}
        </span>

        {/* state and Package */}
        <Row>
          <Col>
            <div className={"listConatiner"}>
              <p>
                Email:{" "}
                <span className={"historyListRight t-t-c"}>{user?.email}</span>
              </p>
            </div>
          </Col>
          <Col>
            <div className={"listConatiner"}>
              <p>
                Contact #:{" "}
                <span className={"historyListRight t-t-c"}>
                  {user?.contact}
                </span>
              </p>
            </div>
          </Col>
        </Row>

        {/* Xity and zipxode */}
        <Row>
          <Col>
            <div className={"listConatiner"}>
              <p>
                City:{" "}
                <span className={"historyListRight t-t-c"}>{user?.city}</span>
              </p>
            </div>
          </Col>
          <Col>
            <div className={"listConatiner"}>
              <p>
                State:{" "}
                <span className={"historyListRight t-t-c"}>
                  {user?.state?.state}
                </span>
              </p>
            </div>
          </Col>
        </Row>
        {/* Email */}
        <Row>
          <Col>
            <div className={"listConatiner"}>
              <p>
                Address:{" "}
                <span className={"historyListRight"}>
                  <ShowMoreShowLessText text={user?.address} />
                </span>
              </p>
            </div>
          </Col>
        </Row>
        {/* caseNumber and deadLineDate and  dateOfBirth */}
        <Row>
          <Col>
            <div className={"listConatiner"}>
              <p>
                Zip Code:{" "}
                <span className={"historyListRight t-t-c"}>
                  {user?.zipCode}
                </span>
              </p>
            </div>
          </Col>
          <Col>
            <div className={"listConatiner"}>
              <p>
                Member Since:{" "}
                <span className={"historyListRight t-t-c"}>
                  {moment(user?.createdAt).format("ll")}
                </span>
              </p>
            </div>
          </Col>
          <Col>
            <div className={"listConatiner"}>
              <p>
                Profile Visits:{" "}
                <span className={"historyListRight t-t-c"}>
                  {user?.profileVisits}
                </span>
              </p>
            </div>
          </Col>
        </Row>
        {/* Address */}
        <Row>
          <Col>
            <div className={"listConatiner"}>
              <p>
                Description:{" "}
                <span className={"historyListRight t-t-c"}>
                  <ShowMoreShowLessText text={user?.companyDescription} />
                </span>
              </p>
            </div>
          </Col>
        </Row>
        {/* Leads */}
        <Row>
          <Col>
            <div className={"listConatiner"}>
              <p>
                Leads:{" "}
                <span className={"historyListRight t-t-c ms-2 c-p"}>
                  <PopOverUser data={leads} />
                </span>
              </p>
            </div>
          </Col>
        </Row>

        {/* Services */}
        <p className="detailHeading">Service(s) Detail</p>
        <div className="">
          <table className="table" id="dataTable" width="100%" cellspacing="0">
            <thead>
              <tr>
                <th>#</th>
                <th>Service Name</th>
                <th>All Time Bids</th>
                <th>Fee</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {user?.services?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item?.service?.name || "Service"}</td>
                    <td>{item?.allTimeBids || 0}</td>
                    <td>${parseFloat(item?.fees).toFixed(2)}</td>
                    <td>
                      <ShowMoreShowLessText text={item?.description} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {/* Certificates */}
        {user?.certificates?.length > 0 && (
          <>
            <p className="detailHeading">Certificates</p>
            <div className="">
              <table
                className="table"
                id="dataTable"
                width="100%"
                cellspacing="0"
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Certificate Name</th>
                    <th>Certificate Decription</th>
                    <th>Company's Description</th>
                  </tr>
                </thead>
                <tbody>
                  {user?.certificates?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item?.certificate?.name}</td>
                        <td>
                          <ShowMoreShowLessText
                            text={item?.certificate?.description}
                          />
                        </td>
                        <td>
                          <ShowMoreShowLessText text={item?.description} />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        )}

        <IconButton className="close-icon-modal" onClick={setOpen}>
          <CloseIcon />
        </IconButton>
        {/* btn */}
        {/* <div className="container-login100-form-btn mt-4">
          <div className="wrap-login100-form-btn">
            <div className="login100-form-bgbtn"></div>
            <button className="login100-form-btn" onClick={setOpen}>
              Close
            </button>
          </div>
        </div> */}
      </form>
    </ModalSkeleton>
  );
};

export default UserDetailModal;
