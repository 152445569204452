import * as React from "react";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import moment from "moment";

import ShowMoreShowLessText from "./ShowMoreShowLessText";
import ExpandWrapper from "./ExpandWrapper";

export default function PopOverUser({ data }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <span
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        // className="update-btn w-120px"
      >
        View Detail
      </span>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <Box sx={{ width: 600 }}>
          {/* Leads */}
          {data?.map((e, i) => (
            <>
              <p className="detailHeading2">Lead #{++i}</p>
              <div className={"listConatinerSimple"}>
                <p>
                  User Name:{" "}
                  <span className={"historyListRight t-t-c"}>
                    {`${e?.firstName} ${e?.lastName}`}
                  </span>
                </p>
              </div>
              <div className={"listConatinerSimple"}>
                <p>
                  User Email:{" "}
                  <span className={"historyListRight t-t-c"}>{e?.email}</span>
                </p>
              </div>
              <div className={"listConatinerSimple"}>
                <p>
                  User Contact #:{" "}
                  <span className={"historyListRight t-t-c"}>{e?.contact}</span>
                </p>
              </div>
              <div className={"listConatinerSimple"}>
                <p>
                  Total Tokens:{" "}
                  <span className={"historyListRight t-t-c"}>
                    {e?.totalTokens}
                  </span>
                </p>
              </div>
              <div className={"listConatinerSimple"}>
                <p>
                  Lead Created At:{" "}
                  <span className={"historyListRight t-t-c"}>
                    {moment(e?.createdAt).format("lll")}
                  </span>
                </p>
              </div>
              <div className={"listConatinerSimple"}>
                <p>
                Preference:{" "}
                  <span className={"historyListRight t-t-c"}>
                    {e?.preference}
                  </span>
                </p>
              </div>
              {data?.length !== i && <hr />}
              {data?.length === i && <div className="mt-3" />}
            </>
          ))}
        </Box>
      </Popover>
    </div>
  );
}
