import "../assets/Styles/forgot.css";
import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

export default function ModalSkeleton({
  open,
  setOpen,
  children,
  type,
  overflow,
  backdrop,
}) {
  const handleClose = () => setOpen(false);

  const customStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: type === "big" ? 1000 : 500,
    bgcolor: "background.paper",
    border: "2px solid var(--main-color)",
    boxShadow: 24,
    p: 4,
    borderRadius: "10px",
    maxHeight: "80vh",
    overflowY: "scroll !important",
  };

  return (
    <div className={`${type === "big" && "big_modal_new"} `}>
      <Modal
        disableBackdropClick
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        // onClose={handleClose}
        onClose={(_, reason) => {
          
          if (backdrop && reason !== "backdropClick") {
            handleClose();
          } else if (!backdrop) {
            handleClose();
          }
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box
            sx={customStyle}
            className={`modal-scroll over-floe-unset-x ${
              overflow == true && "modal-overflow-scroll-z"
            }`}
          >
            {children}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
