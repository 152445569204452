import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import parse from "html-react-parser";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  CardMedia,
  IconButton,
  ListItemButton,
  ListItemIcon,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Collapse from "@mui/material/Collapse";
import CardContent from "@mui/material/CardContent";
import { pdfUrlUrl } from "../Config/apiUrl";
import QuestionAccordian from "./QuestionAccordian";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function UIList({
  t1,
  t2,
  d1,
  d2,
  Icon,
  description = [],
  expand = false,
  expandedHeading,
  topicDescription,
  pdf,
  video,
  questions,
}) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <List
      sx={{
        width: "100%",
        bgcolor: "background.paper",
        boxShadow: 1,
        marginBottom: "1rem",
        borderRadius: "0.5rem",
      }}
    >
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <Icon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={`${t1 ? t1 : ""}`}
          secondary={`${d1 ? d1 : ""}`}
        />
        <ListItemText
          primary={`${t2 ? t2 : ""}`}
          secondary={`${d2 ? d2 : ""}`}
        />
      </ListItem>
      {expand && (
        <div className={`ex-more0I-b ${expanded && "top-4px"}`}>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </div>
      )}

      {/* Collapse */}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {/* Questions */}
          {questions?.length > 0 &&
            questions?.map((q, i) => (
              <QuestionAccordian key={i} data={q} i={i} />
            ))}
          {/* video */}
          {video && (
            <CardMedia
              className="my-4"
              component="video"
              height="194"
              controls
              autoplay
              src={video}
            />
          )}
          {/* PDF */}
          {pdf && (
            <>
              <h4>PDFs</h4>
              <div className="d-flex flex-wrap">
                {pdf?.map((e, i) => (
                  <ListItem disablePadding className="my-3 me-3 w-25" key={i}>
                    <ListItemButton onClick={() => window.open(pdfUrlUrl(e))}>
                      <ListItemIcon>
                        <PictureAsPdfIcon />
                      </ListItemIcon>
                      <ListItemText primary="PDF" />
                    </ListItemButton>
                  </ListItem>
                ))}
              </div>
            </>
          )}
          <h5>{expandedHeading}</h5>
          {topicDescription && parse(topicDescription)}
          {/* description array */}
          {description?.map((e, i) => (
            <ListItem key={i}>
              <ListItemAvatar>
                <Icon />
              </ListItemAvatar>
              <ListItemText className="bullet-list" primary={e} />
            </ListItem>
          ))}
        </CardContent>
      </Collapse>
    </List>
  );
}
