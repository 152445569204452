import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { BiChevronLeft } from "react-icons/bi";
import { BsFillJournalBookmarkFill } from "react-icons/bs";
import {
  BiChevronRight,
  BiMessageAltCheck,
  BiBuildingHouse,
} from "react-icons/bi";
import { AiFillQuestionCircle, AiOutlineUser } from "react-icons/ai";
import {
  MdOutlineLogout,
  MdOutlineRealEstateAgent,
  MdPayment,
  MdPlayLesson,
  MdSecurity,
} from "react-icons/md";
import {
  MdImportContacts,
  MdOutlineSettingsSystemDaydream,
} from "react-icons/md";
import { SiCoursera } from "react-icons/si";
import { FiUsers } from "react-icons/fi";
import {
  companyStatus,
  signOutRequest,
  ToggleDrawer,
} from "../redux/auth/authSlice";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { appLogo } from "../constant/imagePath";
import { VscFeedback } from "react-icons/vsc";
import { FaQuora } from "react-icons/fa";
import { SiMobxstatetree } from "react-icons/si";
import { SiMicrosoftexcel } from "react-icons/si";
import { FaBookOpen, FaCity } from "react-icons/fa";
import { BsBook } from "react-icons/bs";

import { AiFillCaretDown } from "react-icons/ai";

const SideNavbar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigation = useNavigate();
  // const [open, setOpen] = useState(false);
  const open = useSelector((state) => state.authReducer?.isOpen);
  const { status } = useSelector((state) => state?.authReducer);

  console.log(status, "reduxStore reduxStore reduxStore");

  const [selected, setSelected] = useState("/");

  function SidebarItem({ route, text, position, icon, subTitle }) {
    return (
      <li
        className={`nav-item ${position == route && "activeSideItem"} ${
          open && "isOpen"
        }`}
      >
        {!subTitle ? (
          <Link to={route} className="mouse-cursor sideBarNavLink">
            {icon}
            {open && <span>{text}</span>}
          </Link>
        ) : (
          <div className="subLinksMain cus_b_subLinksMain">
            <Link to={route} className="mouse-cursor sideBarNavLink">
              {icon}
              {open && <span>{text}</span>}
              <span className="dropOpenMain">
                <AiFillCaretDown />
              </span>
            </Link>
            {selected == "/company" && (
              <span>
                {subTitle?.map((item) => {
                  const stateStore =
                    item?.text == "All Companies"
                      ? "all"
                      : item?.text == "Active Companies"
                      ? true
                      : item?.text == "Deactive Companies"
                      ? "false"
                      : item?.text == "Unclaimed Business" && "unclaimed";
                  console.log(
                    stateStore,
                    " status == stateStore  status == stateStore "
                  );

                  return (
                    <li
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        dispatch(companyStatus(item?.status));
                        navigation("/company", { state: stateStore });
                      }}
                    >
                      {
                        <a
                          style={{
                            color: "#6499fe",

                            textDecoration: status == stateStore && "underline",
                          }}
                        >
                          {item?.text}
                        </a>
                      }
                    </li>
                  );
                })}
              </span>
            )}
          </div>
        )}
      </li>
    );
  }

  const handleDrawerToggle = async () => {
    await dispatch(ToggleDrawer(!open));
    // setOpen(!open);
  };
  useEffect(() => {
    const pathName = location?.pathname;
    setSelected(pathName);
    if (location?.pathname != "/company") {
      dispatch(companyStatus("all"));
    }
  }, [location]);

  function logoutUser() {
    dispatch(signOutRequest());
    navigation("/login");
  }

  return (
    <>
      <ul className="navbar-nav customSideBar" id="accordionSidebar">
        <div className="sideBarHeader">
          {open ? (
            <>
              <div className="sideBarLogo" onClick={() => {}}>
                <img src={appLogo} alt="" className="image-fluid" />
                {/* 🐵😎🤗🤣🙌🎶🌹 */}
              </div>
              <BiChevronLeft
                size={25}
                color="var(--main-color)"
                onClick={() => {
                  handleDrawerToggle();
                }}
              />
            </>
          ) : (
            <BiChevronRight
              size={25}
              color="var(--main-color)"
              onClick={() => {
                handleDrawerToggle();
              }}
            />
          )}
        </div>

        <hr className="sideBarDivider my-0" />

        <SidebarItem
          text={"Dashboard"}
          route="/"
          position={selected}
          icon={<FiUsers size={25} color="var(--main-color)" />}
        />
        {/* States */}

        {/* Companies */}
        <SidebarItem
          text={"Companies"}
          route="/company"
          position={selected}
          icon={<BiBuildingHouse size={25} color="var(--main-color)" />}
          subTitle={[
            {
              text: "All Companies",
              icon: <BiBuildingHouse size={25} color="var(--main-color)" />,
              position: { selected },
              status: "all",
            },
            {
              text: "Active Companies",
              icon: <BiBuildingHouse size={25} color="var(--main-color)" />,
              position: { selected },
              status: true,
            },
            {
              text: "Deactive Companies",
              icon: <BiBuildingHouse size={25} color="var(--main-color)" />,
              position: { selected },
              status: false,
            },
            {
              text: "Unclaimed Business",
              icon: <BiBuildingHouse size={25} color="var(--main-color)" />,
              position: { selected },
              status: "unclaimed",
            },
          ]}
        />
        {/* services */}
        <SidebarItem
          text={"Services"}
          route="/services"
          position={selected}
          icon={<VscFeedback size={25} color="var(--main-color)" />}
        />
        <SidebarItem
          text={"Packages"}
          route="/package"
          position={selected}
          icon={<MdPayment size={25} color="var(--main-color)" />}
        />

        {/* contactUs */}
        <SidebarItem
          text={"Contact Us"}
          route="/contactUs"
          position={selected}
          icon={
            <MdOutlineRealEstateAgent size={25} color="var(--main-color)" />
          }
        />
        {/* Refunds */}
        <SidebarItem
          text={"Refunds"}
          route="/complains"
          position={selected}
          icon={
            <MdOutlineRealEstateAgent size={25} color="var(--main-color)" />
          }
        />
        {/* complains */}
        <SidebarItem
          text={"Certificates"}
          route="/Certificates"
          position={selected}
          icon={<FaCity size={25} color="var(--main-color)" />}
        />

        {/* city */}
        <SidebarItem
          text={"Create Bussines"}
          route="/createBussines"
          position={selected}
          icon={<FaCity size={25} color="var(--main-color)" />}
        />
        <SidebarItem
          text={"Earning"}
          route="/earning"
          position={selected}
          icon={<FaCity size={25} color="var(--main-color)" />}
        />
        <SidebarItem
          text={"Queries"}
          route="/queries"
          position={selected}
          icon={<FaCity size={25} color="var(--main-color)" />}
        />
        {/* state */}
        <SidebarItem
          text={"States"}
          route="/state"
          position={selected}
          icon={<SiMobxstatetree size={25} color="var(--main-color)" />}
        />
        <SidebarItem
          text={"Templates"}
          route="/templates"
          position={selected}
          icon={<BsBook size={25} color="var(--main-color)" />}
        />
        <SidebarItem
          text={"Cms"}
          route="/cms"
          position={selected}
          icon={<BsBook size={25} color="var(--main-color)" />}
        />

        {/* <SidebarItem
          text={"Packages"}
          route="/packages"
          position={selected}
          icon={<MdPayment size={25} color="var(--main-color)" />}
        /> */}
        {/* <SidebarItem
          text={"Courses"}
          route="/courses"
          position={selected}
          icon={<SiCoursera size={25} color="var(--main-color)" />}
        />

        <SidebarItem
          text={"Lessons"}
          route="/lessons"
          position={selected}
          icon={<MdPlayLesson size={25} color="var(--main-color)" />}
        />

        <SidebarItem
          text={"Question"}
          route="/question"
          position={selected}
          icon={<AiFillQuestionCircle size={25} color="var(--main-color)" />}
        />

        <SidebarItem
          text={"Exam"}
          route="/exam"
          position={selected}
          icon={
            <BsFillJournalBookmarkFill size={25} color="var(--main-color)" />
          }
        />

        <SidebarItem
          text={"Security Qs"}
          route="/security-questions"
          position={selected}
          icon={<MdSecurity size={25} color="var(--main-color)" />}
        />
        <SidebarItem
          text={"Other Qs"}
          route="/after-exam-questions"
          position={selected}
          icon={<MdSecurity size={25} color="var(--main-color)" />}
        />

        <SidebarItem
          text={"User Requests"}
          route="/requests"
          position={selected}
          icon={<BiMessageAltCheck size={25} color="var(--main-color)" />}
        />

        <SidebarItem
          text={"Messages"}
          route="/contact"
          position={selected}
          icon={<MdImportContacts size={25} color="var(--main-color)" />}
        />

        <SidebarItem
          text={"FAQs"}
          route="/faq"
          position={selected}
          icon={<FaQuora size={25} color="var(--main-color)" />}
        />

        <SidebarItem
          text={"Reports"}
          route="/reports"
          position={selected}
          icon={<SiMicrosoftexcel size={25} color="var(--main-color)" />}
        />

        <SidebarItem
          text={"CMS"}
          route="/cms"
          position={selected}
          icon={
            <MdOutlineSettingsSystemDaydream
              size={25}
              color="var(--main-color)"
            />
          }
        /> */}

        {/* <SidebarItem
          text={"Users"}
          route="/allusers"
          position={selected}
          icon={<FiUsers size={25} color="var(--main-color)" />}
        />

        <SidebarItem
          text={"Update Password"}
          route="/updatepassword"
          position={selected}
          icon={
            <RiLockPasswordLine src={refreshIcon} size={25} color="var(--main-color)" />
          }
        /> */}

        <li className={`nav-item c-p  ${open && "isOpen"}`}>
          <div
            className="mouse-cursor sideBarNavLink"
            onClick={() => {
              logoutUser();
            }}
          >
            <MdOutlineLogout size={25} color="var(--main-color)" />
            {open && <span>Logout</span>}
          </div>
        </li>
      </ul>
    </>
  );
};

export default SideNavbar;
