import React, { useEffect, useState } from "react";
import Quill from "react-quill";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import MuiButton from "@mui/material/Button";
import { Delete, Get, Patch, Post } from "../Axios/AxiosFunctions";
import AddImageComponent from "../components/AddImageComponent";
import { BaseURL, apiHeader, urlToObject, imageUrl } from "../Config/apiUrl";
import { toast } from "react-toastify";
import BeforeLoadContentLoader from "../components/BeforeLoadContentLoader";
import ScreenBoiler from "../components/ScreenBoiler";
import "../assets/Styles/cms.css";

const HomeCms = () => {
  const pageData = useLocation()?.state?.item;
  // console.log("🚀 ~ file: HomeCms.js ~ line 16 ~ HomeCms ~ pageData", pageData)
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const navigate = useNavigate();
  const [isApiCall, setIsApiCall] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pageName] = useState(pageData?.pageName);
  const [pageId] = useState(pageData?._id);
  // Home Section States Start //

  const [section1Image, setSection1Image] = useState({});

  const [section1Title, setSection1Title] = useState("");
  const [section1Description, setSection1Description] = useState({});
  const [section2Title, setSection2Title] = useState("");
  const [section2Description, setSection2Description] = useState({});
  const [section2Image1, setSection2Image1] = useState({});
  const [section2Image2, setSection2Image2] = useState({});
  const [section2Image3, setSection2Image3] = useState({});

  const [section3Title, setSection3Title] = useState("");
  const [section3Description, setSection3Description] = useState({});

  const [section4SubTitle1, setSection4SubTitle1] = useState("");
  const [section4SubDescription1, setSection4SubDescription1] = useState("");
  const [section4SubIcon1, setSection4SubIcon1] = useState("");
  const [section4SubTitle2, setSection4SubTitle2] = useState("");
  const [section4SubDescription2, setSection4SubDescription2] = useState("");
  const [section4SubIcon2, setSection4SubIcon2] = useState("");
  const [section4SubTitle3, setSection4SubTitle3] = useState("");
  const [section4SubDescription3, setSection4SubDescription3] = useState("");
  const [section4SubIcon3, setSection4SubIcon3] = useState("");
  const [section4SubTitle4, setSection4SubTitle4] = useState("");
  const [section4SubDescription4, setSection4SubDescription4] = useState("");
  const [section4SubIcon4, setSection4SubIcon4] = useState("");

  const [section5Title, setSection5Title] = useState({});
  const [section5Description, setSection5Description] = useState("");
  const [section5Image, setSection5Image] = useState("");

  // Home Section States End //

  const getData = async () => {
    const url = BaseURL(`cms/page/all/new?pages=${pageData?.pageName}`);
    setIsApiCall(true);
    const response = await Get(url, accessToken);

    if (response !== undefined) {
      setSection1Title(response?.data?.data?.section1_title);
      setSection1Description(response?.data?.data?.section1_description);

      setSection2Title(response?.data?.data?.section2_title);
      setSection2Description(response?.data?.data?.section2_description);

      setSection3Title(response?.data?.data?.section3_title);
      setSection3Description(response?.data?.data?.section3_description);

      setSection4SubTitle1(response?.data?.data?.section4_sub_title1);
      setSection4SubDescription1(
        response?.data?.data?.section4_sub_description1
      );

      setSection4SubTitle2(response?.data?.data?.section4_sub_title2);
      setSection4SubDescription2(
        response?.data?.data?.section4_sub_description2
      );

      setSection4SubTitle3(response?.data?.data?.section4_sub_title3);
      setSection4SubDescription3(
        response?.data?.data?.section4_sub_description3
      );

      setSection4SubTitle4(response?.data?.data?.section4_sub_title4);
      setSection4SubDescription4(
        response?.data?.data?.section4_sub_description4
      );

      setSection5Title(response?.data?.data?.section5_title);
      setSection5Description(response?.data?.data?.section5_description);

      //   Single Image
      const section1Image = await urlToObject(
        imageUrl(response?.data?.data?.section1_image)
      );

      setSection1Image(section1Image);

      const section2Image1 = await urlToObject(
        imageUrl(response?.data?.data?.section2_image1)
      );

      setSection2Image1(section2Image1);

      const section2Image2 = await urlToObject(
        imageUrl(response?.data?.data?.section2_image2)
      );

      setSection2Image2(section2Image2);

      const section2Image3 = await urlToObject(
        imageUrl(response?.data?.data?.section2_image3)
      );

      setSection2Image3(section2Image3);

      const section4SubIcon1 = await urlToObject(
        imageUrl(response?.data?.data?.section4_sub_icon1)
      );

      setSection4SubIcon1(section4SubIcon1);

      const section4SubIcon2 = await urlToObject(
        imageUrl(response?.data?.data?.section4_sub_icon2)
      );

      setSection4SubIcon2(section4SubIcon2);

      const section4SubIcon3 = await urlToObject(
        imageUrl(response?.data?.data?.section4_sub_icon3)
      );

      setSection4SubIcon3(section4SubIcon3);

      const section4SubIcon4 = await urlToObject(
        imageUrl(response?.data?.data?.section4_sub_icon4)
      );

      setSection4SubIcon4(section4SubIcon4);

      //   Single Image\
      const section5Image = await urlToObject(
        imageUrl(response?.data?.data?.section5_image)
      );
      setSection5Image(section5Image);
    }
    setIsApiCall(false);

  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    let timeOut = setTimeout(() => {
      setIsLoading(false);
    }, 2800);
    return () => clearTimeout(timeOut);
  }, []);

  //   handleSubmit
  const handleSubmit = async () => {
    const params = {
      _id: pageId,
      pageName,

      section1_image: section1Image,
      section1_title: section1Title,
      section1_description: section1Description,

      section2_title: section2Title,

      section2_description: section2Description,
      section2_image1: section2Image1,
      // section2_image2: section2Image2,
      // section2_image3: section2Image3,

      section3_title: section3Title,
      section3_description: section3Description,

      section4_sub_title1: section4SubTitle1,
      section4_sub_description1: section4SubDescription1,
      section4_sub_icon1: section4SubIcon1,
      section4_sub_title2: section4SubTitle2,
      section4_sub_description2: section4SubDescription2,
      section4_sub_icon2: section4SubIcon2,
      section4_sub_title3: section4SubTitle3,
      section4_sub_description3: section4SubDescription3,
      section4_sub_icon3: section4SubIcon3,
      section4_sub_title4: section4SubTitle4,
      section4_sub_description4: section4SubDescription4,
      section4_sub_icon4: section4SubIcon4,

      section5_title: section5Title,
      section5_description: section5Description,
      section5_image: section5Image,
    };

    const formData = new FormData();
    for (let key in params) {
      params[key] && formData.append(key, params[key]);
    }

    const url = BaseURL("cms/page/update");
    setIsApiCall(true);
    const response = await Patch(url, formData, apiHeader(accessToken, true));
    setIsApiCall(false);

    if (response !== undefined) {
      toast.success("Page data updated successfully");
      getData();
    }
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <ScreenBoiler>
      <>
        <section
          className="home-section"
          style={{ visibility: isApiCall && "hidden" }}
        >
          <div style={{ textAlign: "end" }} className="homepage_heading">
            <MuiButton
              onClick={() => navigate(-1)}
              variant="contained"
              color="primary"
              className="mt-3 custom-btn rounded-lg radius-8 mb-4"
            >
              Go Back
            </MuiButton>
          </div>
          <div className="homepage_heading">
            <h1>{pageName?.toUpperCase()} PAGE DETAILS</h1>
          </div>

          {isApiCall && <BeforeLoadContentLoader />}

          <div className="homeSection1">
            <p className="mb-3 mt-3">Section 1 Image</p>
            <div className="section1-img-box">
              <AddImageComponent
                index={null}
                item={section1Image}
                onSubmit={(data) => setSection1Image(data)}
                initialState={section1Image}
              />
            </div>
          </div>

          <div className="homeSection2">
            {/* 00 */}
            <p className="mb-0 mt-3">Section 1 Title</p>
            {/* <input
              className="admin_Input_cms"
              type="text"
              name="section1 title"
              value={section1Title}
              onChange={(e) => setSection1Title(e.target.value)}
            /> */}
            <Quill
              theme="snow"
              value={section1Title}
              onChange={setSection1Title}
            />

            <p className="mb-0 mt-3">Section 1 Description</p>
            <textarea
              className="admin_Input_cms"
              rows="4"
              cols="50"
              value={section1Description}
              onChange={(e) => setSection1Description(e.target.value)}
            ></textarea>
            {/* 11 */}
            <p className="mb-0 mt-3">Section 2 Title</p>
            <input
              className="admin_Input_cms"
              type="text"
              name="section2 title"
              value={section2Title}
              onChange={(e) => setSection2Title(e.target.value)}
            />
            <p className="mb-0 mt-3">Section 2 description</p>
            <textarea
              className="admin_Input_cms"
              rows="4"
              cols="50"
              value={section2Description}
              onChange={(e) => setSection2Description(e.target.value)}
            ></textarea>
            <p className="mb-3 mt-3">Section 2 Image 1</p>
            <div className="section1-img-box">
              <AddImageComponent
                index={null}
                item={section2Image1}
                onSubmit={(data) => setSection2Image1(data)}
                initialState={section2Image1}
              />
            </div>
            {/* <div className="section1-img-box">
              <AddImageComponent
                index={null}
                item={section2Image2}
                onSubmit={(data) => setSection2Image2(data)}
                initialState={section2Image2}
              />
            </div>
            <div className="section1-img-box">
              <AddImageComponent
                index={null}
                item={section2Image3}
                onSubmit={(data) => setSection2Image3(data)}
                initialState={section2Image3}
              />
            </div> */}
          </div>

          <div className="homeSection3">
            <p className="mb-0 mt-3">Section 3 Title</p>
            <input
              className="admin_Input_cms"
              type="text"
              name="section3 title"
              value={section3Title}
              onChange={(e) => setSection3Title(e.target.value)}
            />
            <p className="mb-0 mt-3">Section 3 Description</p>
            <textarea
              className="admin_Input_cms"
              rows="4"
              cols="50"
              value={section3Description}
              onChange={(e) => setSection3Description(e.target.value)}
            ></textarea>
          </div>

          <div className="homeSection4">
            <p className="mb-0 mt-3">Section 4 SubTitle 1</p>
            <input
              className="admin_Input_cms"
              type="text"
              name="section4 subTitle1"
              value={section4SubTitle1}
              onChange={(e) => setSection4SubTitle1(e.target.value)}
            />
            <p className="mb-0 mt-3">Section 4 SubDescription 1</p>
            <textarea
              className="admin_Input_cms"
              rows="4"
              cols="50"
              value={section4SubDescription1}
              onChange={(e) => setSection4SubDescription1(e.target.value)}
            ></textarea>
            <p className="mb-3 mt-3">Section 4 Icon 1</p>
            <div className="section1-img-box">
              <AddImageComponent
                index={null}
                item={section4SubIcon1}
                onSubmit={(data) => setSection4SubIcon1(data)}
                initialState={section4SubIcon1}
              />
            </div>
            <p className="mb-0 mt-3">Section 4 SubTitle 2</p>
            <input
              className="admin_Input_cms"
              type="text"
              name="section4 subTitle2"
              value={section4SubTitle2}
              onChange={(e) => setSection4SubTitle2(e.target.value)}
            />
            <p className="mb-0 mt-3">Section 4 SubDescription 2</p>
            <textarea
              className="admin_Input_cms"
              rows="4"
              cols="50"
              value={section4SubDescription2}
              onChange={(e) => setSection4SubDescription2(e.target.value)}
            ></textarea>
            <p className="mb-3 mt-3">Section 4 Icon 2</p>
            <div className="section1-img-box">
              <AddImageComponent
                index={null}
                item={section4SubIcon2}
                onSubmit={(data) => setSection4SubIcon2(data)}
                initialState={section4SubIcon2}
              />
            </div>
            <p className="mb-0 mt-3">Section 4 SubTitle 3</p>
            <input
              className="admin_Input_cms"
              type="text"
              name="section4 subTitle3"
              value={section4SubTitle3}
              onChange={(e) => setSection4SubTitle3(e.target.value)}
            />
            <p className="mb-0 mt-3">Section 4 SubDescription 3</p>
            <textarea
              className="admin_Input_cms"
              rows="4"
              cols="50"
              value={section4SubDescription3}
              onChange={(e) => setSection4SubDescription3(e.target.value)}
            ></textarea>
            <p className="mb-3 mt-3">Section 4 Icon 3</p>
            <div className="section1-img-box">
              <AddImageComponent
                index={null}
                item={section4SubIcon3}
                onSubmit={(data) => setSection4SubIcon3(data)}
                initialState={section4SubIcon3}
              />
            </div>
            <p className="mb-0 mt-3">Section 4 SubTitle 4</p>
            <input
              className="admin_Input_cms"
              type="text"
              name="section4 subTitle4"
              value={section4SubTitle4}
              onChange={(e) => setSection4SubTitle4(e.target.value)}
            />
            <p className="mb-0 mt-3">Section 4 SubDescription 4</p>
            <textarea
              className="admin_Input_cms"
              rows="4"
              cols="50"
              value={section4SubDescription4}
              onChange={(e) => setSection4SubDescription4(e.target.value)}
            ></textarea>
            <p className="mb-3 mt-3">Section 4 Icon 4</p>
            <div className="section1-img-box">
              <AddImageComponent
                index={null}
                item={section4SubIcon4}
                onSubmit={(data) => setSection4SubIcon4(data)}
                initialState={section4SubIcon4}
              />
            </div>
          </div>

          <div className="homeSection5">
            <p className="mb-0 mt-3">Section 5 Title</p>
            <textarea
              className="admin_Input_cms"
              rows="4"
              cols="50"
              value={section5Title}
              onChange={(e) => setSection5Title(e.target.value)}
            ></textarea>
            <p className="mb-0 mt-3">Section 5 Description</p>
            <textarea
              className="admin_Input_cms"
              rows="4"
              cols="50"
              value={section5Description}
              onChange={(e) => setSection5Description(e.target.value)}
            ></textarea>
            <p className="mb-3 mt-3">Section 5 Image</p>
            <div className="section1-img-box">
              <AddImageComponent
                index={null}
                item={section5Image}
                onSubmit={(data) => setSection5Image(data)}
                initialState={section5Image}
              />
            </div>
          </div>
          <div className="home-cms-btn mt-3 text-center mb-4">
            <MuiButton
              style={{ color: "#fff" }}
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              className="mt-3 custom-btn rounded-lg radius-8 ms-4"
              disabled={isApiCall}
            >
              {isApiCall ? "Updating..." : "Update"}
            </MuiButton>
          </div>
        </section>
      </>
    </ScreenBoiler>
  );
};

export default HomeCms;
