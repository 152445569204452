export const apiUrl =
  "https://pest-radar-backend-demo-39e92f8da727.herokuapp.com"; //talal
// export const apiUrl = "https://811c-119-155-153-170.ngrok-free.app"; //talal

export const imageUrl = (url) =>
  `https://pest-radar-dev.s3.amazonaws.com/${url}`;
export const pdfUrlUrl = (url) =>
  `https://pest-radar-dev.s3.amazonaws.com/${url}`;

export const BaseURL = (link) => {
  return `${apiUrl}/api/v1/${link}`;
};

export const apiHeader = (token, isFormData) => {
  if (token && !isFormData) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning": "69420",
      },
    };
  }
  if (token && isFormData) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
        "ngrok-skip-browser-warning": "69420",
      },
    };
  }
  if (!token && !isFormData) {
    return {
      headers: {
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning": "69420",
      },
    };
  }

  if (!token && isFormData) {
    return {
      headers: {
        "Content-Type": "multipart/form-data",
        "ngrok-skip-browser-warning": "69420",
      },
    };
  }
};

export const urlToObject = async (imageUrl) => {
  try {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const file = new File([blob], "image.png", { type: blob.type });
    return file;
  } catch (err) {
    console.log("URL To Object", err);
  }
};

export const recordsLimit = 10;

export const formRegEx = /([a-z])([A-Z])/g;
export const formRegExReplacer = "$1 $2";

export function isEmptyObject(obj) {
  return Object.keys(obj).length == 0;
}
export const exceptThisSymbols = ["e", "E", "+", "-", "."];

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
