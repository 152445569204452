import React, { useEffect, useRef, useState } from "react";
import ScreenBoiler from "../components/ScreenBoiler";
import { Container, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { apiHeader, BaseURL, exceptThisSymbols } from "../Config/apiUrl";
import { Get, Post } from "../Axios/AxiosFunctions";
import { useSelector } from "react-redux";
import "../assets/Styles/CreateBussines.css";
import excel from "../assets/images/excel.png";
import CreateIcon from "@mui/icons-material/Create";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CloseIcon from "@mui/icons-material/Close";
import { json } from "../assets/Json";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Papa from "papaparse";
import { City } from "country-state-city";

const CreateBussines = () => {
  const [companyName, setCompanyName] = useState("");
  const [userName, setUserName] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [country, setCountry] = useState("US");
  const [password, setPassword] = useState("");
  const [cityOptions, setCityOptions] = useState([]);
  const navigate = useNavigate();
  const [state, setState] = useState("");
  const [csv, setCsv] = useState(null);
  const [stateSend, setStateSend] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingCompany, setLoadingCompany] = useState(false);
  const accessToken = useSelector((state) => state.authReducer.access_token);

  console.log(csv, "csvcsv");
  console.log("city>>>>??", city?.t);
  const selectInputRef = useRef();
  console.log(state);

  const getData = async () => {
    const response = await Get(BaseURL("state"));
    console.log(response, "response");
    if (response != undefined) {
      setState(response?.data?.data?.states);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      companies: csv,
      state: stateSend?._id,
      stateName: stateSend?.state,
    };

    for (var key in data) {
      console.log(data, "key", data[key]);
      if (data[key] == "" || data[key] == null)
        return toast.warn(
          key == "companies" ? "Please Upload CSV File" : `Please Fill ${key} `
        );
    }

    const formData = new FormData();
    let response;
    setLoading(true);

    // fetch( BaseURL("users/uploadCompaniesInfo"), {
    //   method: "POST",
    //   headers:    {'Content-Type': 'application/json',"Authorization": `Bearer ${accessToken}`},
    //   body: JSON.stringify(data)
    // }).then(res => {
    //   console.log("Request complete! response:", res);
    // }).catch(error=>{
    //   console.log(error,"JAVAAAAA")
    // })

    try {
      response = await Post(
        BaseURL("users/uploadCompaniesInfo"),
        data,
        apiHeader(accessToken)
      ).catch((error) => {
        console.log(error, "errrrrr");
        throw error;
      });
      if (response != undefined) {
        setStateSend("");
        setCsv(null);
        toast.success("Successfully Uploaded");
      }
    } catch (error) {
      console.log(error, "errprrrrrr");
    }

    setLoading(false);
  };
console.log(city,"city city city")

  const handleForm = async (e) => {
    e.preventDefault();
    const body = {
      companyName,
      userName: userName.replaceAll(" ", ""),
      contact,
      email,
      websiteUrl,
      address,
      city: city?.name,
      state: stateSend?._id,
      zipCode: [zipcode],
      country,
      password,
    };

    for (var key in body) {
      console.log(body, "key", body[key]);
      if (body[key] == "" || body[key] == null)
        return toast.warn(`Please Fill ${key} `);
    }
    setLoadingCompany(true);
    const response = await Post(
      BaseURL("users/admin/createCompany"),
      body,
      apiHeader(accessToken)
    );
    setLoadingCompany(false);
    if (response !== undefined) {
      toast.success("Successfully submitted");
      navigate("/");
      setCompanyName("");
      setUserName("");
      setContact("");
      setEmail("");
      setWebsiteUrl("");
      setAddress("");
      setZipcode("");
      setCountry("");
      setPassword("");
    }
  };
  const handleState = (state) => {
    setCity("");
    // let outpu = [];
    // Object.entries(json).forEach(([key, value]) => {
    //   // console.log(key, value);
    //   // return key == "Nevada";
    //   if (key === state) {
    //     outpu = value;
    //   }
    // });



    setCityOptions(City.getCitiesOfState("US", state?.stateCode));



    // console.log(outpu, "outPuuuuuuuuuuuu");
    // setCityOptions(outpu);
  };

  const changeHandler = (event) => {
    // Passing file data (event.target.files[0]) to parse using Papa.parse
    console.log(
      event?.target?.value.split(",")[0].split(" ").at(-1),
      "eventttt"
    );
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        // Iterating data to get column name and their values
        const updates = results.data.map((item) => {
          return {
            companyName: item?.BusinessName,
            contact: item?.Telephone,
            email: item?.Email,
            websiteUrl: item?.WebsiteURL,
            address: item?.Address,
            zipCode: item?.ZIP,
            country: item?.Country,
            city: event?.target?.value.split(",")[0].split(" ").at(-1),
          };
        });

        console.log(updates, "1111111111111");

        setCsv(updates);
      },
    });
    toast.success("File Is Ready To Submit");
  };

  return (
    <ScreenBoiler>
      <style>
        {`
       .css-b62m3t-container{
          width:100%;
          margin-top:10px;
          border: 1px solid #0f62fe;
          border-radius: 5px;
        }
        `}
      </style>
      <section className="reported-ads mt-5">
        <div>
          <div className="buss__sectionOne__main">
            <h1 className="text-center mb-5">Import Pest Radar company</h1>

            <Row className="align-items-center">
              <Col md={6}>
                <Select
                  value={stateSend}
                  onChange={(e) => {
                    setStateSend(e);
                  }}
                  getOptionLabel={(option) => option.state}
                  getOptionValue={(option) => option.state}
                  options={state}
                />
              </Col>
              <Col md={6} className="buss__excel__main__col">
                <div className="buss__excel__main">
                  <div className="check__box__icon">
                    {csv !== null ? (
                      <CheckBoxIcon style={{ color: "white" }} />
                    ) : (
                      ""
                    )}
                  </div>
                  {csv !== null && (
                    <div
                      onClick={() => setCsv(null)}
                      className="check__box_close"
                    >
                      <CloseIcon style={{ color: "white" }} />
                    </div>
                  )}
                  <img src={excel} alt="excel" />
                  <label className="buss__label">
                    <CreateIcon style={{ color: "white" }} />
                    <input
                      type="file"
                      accept=".csv"
                      // onChange={(e) => setCsv(e.target.files[0])}
                      onChange={(e) => {
                        changeHandler(e);
                      }}
                    />
                  </label>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="mt-3 d-flex justify-content-center">
                <button className="buss__submit__btn" onClick={handleSubmit}>
                  {loading ? "Submitting" : "Submit"}
                </button>
              </Col>
            </Row>
          </div>

          <form onSubmit={handleForm}>
            <div className="buss__sectionTwo__main">
              <h1 className="mt-5 text-center">Create Business</h1>
              <div className="buss__form__main">
                <Row>
                  <Col lg={6}>
                    <label>Company Name</label>
                    <input
                      required
                      onChange={(e) => setCompanyName(e.target.value)}
                      type="text"
                    />
                  </Col>

                  <Col lg={6}>
                    <label>User Name</label>
                    <input
                      required
                      onChange={(e) => setUserName(e.target.value)}
                      type="text"
                      onKeyDown={(e) =>
                        ["&"].includes(e.key) && e.preventDefault()
                      }
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <label>Contact</label>
                    <input
                      min={0}
                      required
                      onChange={(e) => setContact(e.target.value)}
                      type="number"
                      onKeyDown={(e) =>
                        exceptThisSymbols.includes(e.key) && e.preventDefault()
                      }
                    />
                  </Col>

                  <Col lg={6}>
                    <label>Email</label>
                    <input
                      required
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <label>Website URL</label>
                    <input
                      required
                      onChange={(e) => setWebsiteUrl(e.target.value)}
                      type="text"
                    />
                  </Col>

                  <Col lg={6}>
                    <label>Address</label>
                    <input
                      required
                      onChange={(e) => setAddress(e.target.value)}
                      type="text"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <label>State</label>
                    <Select
                      ref={selectInputRef}
                      onChange={(e) => {
                        setStateSend(e);
                        handleState(e);
                      }}
                      getOptionLabel={(option) => option.state}
                      getOptionValue={(option) => option.state}
                      options={state}
                    />
                  </Col>

                  <Col lg={6}>
                    <label>City</label>
                    <Select
                      onChange={(e) => {
                        setCity(e);
                      }}
                      value={city}
                      getOptionLabel={(option) => {
                        return option.name;
                      }}
                      getOptionValue={(option) => option.name}
                      options={cityOptions}
                      classNamePrefix="mySelect"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <label>ZIP Code</label>
                    <input
                      min={0}
                      required
                      onChange={(e) => setZipcode(e.target.value)}
                      type="number"
                      onKeyDown={(e) =>
                        exceptThisSymbols.includes(e.key) && e.preventDefault()
                      }
                    />
                  </Col>
                  <Col lg={6}>
                    <label>Country</label>
                    <input
                      required
                      // onChange={(e) => setCountry(e.target.value)}
                      type="text"
                      value={"US"}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <label>Password</label>
                    <input
                      required
                      onChange={(e) => setPassword(e.target.value)}
                      type="password"
                    />
                  </Col>
                </Row>
              </div>

              <div>
                <div className="d-flex justify-content-center mt-5 mb-4">
                  <button disabled={loadingCompany} className="buss__submit__btn">
                    {loadingCompany ? "Submiting..." : "Submit"}{" "}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </ScreenBoiler>
  );
};

export default CreateBussines;
