import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

import { formRegEx, formRegExReplacer, imageUrl } from "../Config/apiUrl";
import ModalSkeleton from "./ModalSkeleton";
import { green } from "@material-ui/core/colors";
import Select from "react-select";

const AddAndEditCertificateModal = ({
  open,
  setOpen,
  data,
  isLoading,
  handleSubmit,
  state,
}) => {
  const selectInputRef = useRef();

  const [name, setName] = useState(data?.name || "");
  const [description, setDescription] = useState(data?.description || "");
  const [stateSend, setStateSend] = useState("");
  const [backState, setBackState] = useState("");

  //   btnClickHandler
  const btnClickHandler = (e) => {
    e.preventDefault();
    const params = {
      name: String(name),
      // description,
      state: stateSend?._id,
    };

    // validate
    for (let key in params)
      if (params[key] === "")
        return toast.warn(
          `${key.replace(formRegEx, formRegExReplacer)} is required.`
        );
    console.log(params);
    handleSubmit(params);
  };
  useEffect(() => {
    if (data) {
      //   const backendState = state.find((item) => {
      //     console.log(item._id, data.state);
      //     return item._id == data.state;
      //   });
      setStateSend(data?.state);
      // setStateSend
    }
  }, []);

  return (
    <ModalSkeleton open={open} setOpen={setOpen}>
      <form className="login100-form validate-form">
        <span className="login100-form-title text-left text-color pb-4">
          {data ? "Edit" : "Add"} Certificate
        </span>

        {/* Name */}
        <div>
          <label>State</label>
          <Select
            value={stateSend}
            ref={selectInputRef}
            onChange={(e) => {
              setStateSend(e);
            }}
            getOptionLabel={(option) => option.state}
            getOptionValue={(option) => option.state}
            options={state}
          />
        </div>
        {/* Name */}
        <div>
          <label>Name</label>
          <input
            className="add-evidence-modal-input full"
            type="text"
            placeholder="Name Here"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>
        {/* <div>
          <label>Description </label>
          <textarea
            className="add-evidence-modal-input full"
            type="text"
            placeholder="Description Here"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
        </div> */}

        {/* btn */}
        <div className="container-login100-form-btn mt-4">
          <div style={{ zIndex: 0 }} className="wrap-login100-form-btn">
            <div className="login100-form-bgbtn"></div>
            <button
              disabled={isLoading}
              className="login100-form-btn"
              onClick={btnClickHandler}
            >
              {isLoading ? "Please Wait..." : "Submit"}
            </button>
          </div>
        </div>
      </form>
    </ModalSkeleton>
  );
};

export default AddAndEditCertificateModal;
