import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Post, Delete, Patch, Get } from "../Axios/AxiosFunctions";
import Pagination from "../components/Pagination";
import TableRowsLoader from "../components/TableRowsLoader";
import moment from "moment";
import { BaseURL, apiHeader, recordsLimit } from "../Config/apiUrl";
import { Button } from "react-bootstrap";
import AreYouSureModal from "../components/AreYouSureModal";
// Mui Button
import MuiButton from "@mui/material/Button";
import { IoMdAddCircleOutline } from "react-icons/io";
import { toast } from "react-toastify";
import ScreenBoiler from "../components/ScreenBoiler";
import AddAndEditPackagesModalP from "../components/AddAndEditPackagesModalP";

function PackgesScreenP() {
  const [limit, setLimit] = useState(recordsLimit);
  const [pageNumber, setPageNumber] = useState(1);
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const [apiData, setApiData] = useState([]);
  const [isGetting, setIsGetting] = useState(false);

  const [isAddAndEditModalOpen, setIsAddAndEditModalOpen] = useState(false);
  const [isAreYouSureModalOpen, setIsAreYouSureModalOpen] = useState(false);
  const [isAddEditApiCall, setIsAddEditApiCall] = useState(false);
  const [isDeleteApiCall, setIsDeleteApiCall] = useState(false);
  const [editingData, setEditingData] = useState(null);
  const [deletingId, setDeletingId] = useState(null);
  const [activeInActiveApiCall, setActiveInActiveApiCall] = useState(false);

  // get Item handler
  const getData = async (pageNo, newLimit) => {
    const url = BaseURL(`packages/admin/all?page=${pageNo}&limit=${newLimit}`);

    setIsGetting(true);
    const response = await Get(url, accessToken);
    setIsGetting(false);
    if (response !== undefined) {
      setApiData(response?.data?.data);
    }
  };

  //HandleActive OR InActive

  const handleActiveInactive = async (active, index, id) => {
    const url = BaseURL(`packages/admin/${id}`);
    const params = {
      active,
    };

    setActiveInActiveApiCall(true);
    const response = await Patch(url, params, apiHeader(accessToken));
    setActiveInActiveApiCall(false);

    if (response !== undefined) {
      const dataCopy = [...apiData];

      dataCopy[index].active = active;
      setApiData(dataCopy);
      toast.success(
        `Service ${active ? "Activated" : "Deactivated"} Successfully`
      );
    }
  };

  // addAndupdateItemHandler
  const addAndupdateItemHandler = async (params) => {
    const url = BaseURL(
      editingData ? `packages/admin/${editingData?._id}` : `packages/admin`
    );
    const fn = editingData?._id ? Patch : Post;

    setIsAddEditApiCall(true);
    const response = await fn(url, params, apiHeader(accessToken));
    setIsAddEditApiCall(false);

    if (response !== undefined) {
      // if we have updated
      if (editingData) {
        const dataCopy = [...apiData];
        const index = dataCopy.findIndex(
          (item) => item._id === editingData._id
        );
        dataCopy[index] = response.data;
        setApiData(dataCopy);
      }
      // if we have added
      else {
        setApiData((p) => [response.data?.data, ...p]);
      }

      toast.success(
        `Package ${editingData ? "Updated" : "Added"} Successfully`
      );
      setEditingData(null);
      setIsAddAndEditModalOpen(false);
    }
  };

  // deleteItemHandler
  const deleteItemHandler = async () => {
    const url = BaseURL(`state/${deletingId}`);

    setIsDeleteApiCall(true);
    const response = await Delete(url, null, apiHeader(accessToken));
    setIsDeleteApiCall(false);

    if (response !== undefined) {
      const dataCopy = [...apiData];
      const index = dataCopy.findIndex((item) => item._id === deletingId);
      dataCopy.splice(index, 1);
      setApiData(dataCopy);
      setIsAreYouSureModalOpen(false);
      toast.success(`State deleted succeccfully!`);
    }
  };

  // fire on component mount
  useEffect(() => {
    getData(1, limit);
  }, []);

  // pagination handler
  const HandlepageChange = (value) => {
    const newPageNumber = pageNumber + value;
    setPageNumber(newPageNumber);
    getData(newPageNumber, limit);
  };

  return (
    <ScreenBoiler>
      <section className="reported-ads mt-5">
        <div className="fieldSetHeaderContainer d-flex flex-column">
          <h1>Packages</h1>
        </div>

        <div className="fieldSetMainContainer">
          <div className="place-add-btn-container mb-4">
            <div className="d-flex align-items-center">
              <p className="m-0 me-3 fw-bold">Records Per Page:</p>
              <select
                value={limit}
                onChange={(e) => {
                  setLimit(e.target.value);
                  setPageNumber(1);
                  HandlepageChange(1, e.target.value);
                }}
                className="px-3 py-2 custom-selection"
              >
                <option value={10} className="pt-2">
                  10
                </option>
                <option value={25}>25</option>
                <option value={50}>50</option>
              </select>
            </div>

            {/* <div className="d-flex align-items-center">
              <MuiButton
                onClick={() => {
                  setEditingData(null);
                  setIsAddAndEditModalOpen(true);
                }}
                variant="contained"
                color="primary"
                className="custom-btn rounded-lg radius-8 ms-4"
                startIcon={<IoMdAddCircleOutline />}
              >
                Add
              </MuiButton>
            </div> */}
          </div>

          {/* Table */}
          {isGetting ? (
            <TableRowsLoader />
          ) : (
            <div className="col-md-12 table_recent">
              <div className="table-responsive">
                <table
                  className="table"
                  id="dataTable"
                  width="100%"
                  cellspacing="0"
                >
                  <thead>
                    <tr>
                      <th>Order</th>
                      <th>Package Type</th>
                      <th>Price</th>
                      <th>Bonus Percentage</th>
                      <th>Bonus Units</th>
                      <th>Recharge Message</th>
                      <th>Total Units Purchased</th>
                      <th>Last Updated</th>
                      <th>Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    {apiData
                      ?.sort((a, b) => a?.order - b?.order)
                      ?.map((item, index) => {
                        return (
                          <tr key={item?._id}>
                            <td>{item?.order}</td>
                            <td className="t-t-c">{item?.packageType}</td>
                            <td className="t-t-c">
                              {parseFloat(item?.price).toFixed(2)}
                            </td>
                            <td className="t-t-c">
                              {parseFloat(item?.bonusPercentage).toFixed(2)}
                            </td>
                            <td className="t-t-c">
                              {parseFloat(item?.bonusUnits).toFixed(0)}
                            </td>
                            <td>{item?.rechargeMessage}</td>
                            <td>{item?.totalUnitsPurchased}</td>
                            <td>
                              {moment(item?.updatedAt).format(
                                "ddd, DD MMM YYYY hh:mm A"
                              )}
                            </td>
                            <td>
                              <ul className="d-flex">
                                <li
                                  className="page-item align-self-end me-2"
                                  onClick={() => {
                                    setEditingData(item);
                                    setIsAddAndEditModalOpen(true);
                                  }}
                                >
                                  <Button className="update-btn">Edit</Button>
                                </li>
                                <li
                                  className="page-item align-self-end me-2"
                                  onClick={handleActiveInactive.bind(
                                    this,
                                    !item?.active,
                                    index,
                                    item?._id
                                  )}
                                >
                                  <Button className="update-btn">
                                    {item?.active ? "Deactivate" : "Activate"}
                                  </Button>
                                </li>
                                {/* <li
                                className="page-item align-self-end ml-4"
                                onClick={() => {
                                  setDeletingId(item?._id);
                                  setIsAreYouSureModalOpen(true);
                                }}
                              >
                                <Button className="delete-btn">Delete</Button>
                              </li> */}
                              </ul>
                            </td>
                          </tr>
                        );
                      })}

                    {apiData?.length == 0 && (
                      <tr className="no-data-table-row">
                        <td colSpan={8}>
                          <div className="d-flex align-items-center justify-content-center no-data-table-content">
                            <p className="">No Data Found!</p>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          <Pagination
            maxLastLength={limit}
            array={apiData}
            HandlepageChange={HandlepageChange}
            pageNumber={pageNumber}
          />
        </div>

        {/* AreYouSureModal */}
        {isAreYouSureModalOpen && (
          <AreYouSureModal
            open={isAreYouSureModalOpen}
            setOpen={setIsAreYouSureModalOpen}
            label={"Are You Sure?"}
            message={`Do you really want to delete this State?`}
            handleYes={deleteItemHandler}
            isLoading={isDeleteApiCall}
          />
        )}

        {isAddAndEditModalOpen && (
          <AddAndEditPackagesModalP
            open={isAddAndEditModalOpen}
            setOpen={setIsAddAndEditModalOpen}
            handleSubmit={addAndupdateItemHandler}
            data={editingData}
            isLoading={isAddEditApiCall}
          />
        )}
      </section>
    </ScreenBoiler>
  );
}

export default PackgesScreenP;
